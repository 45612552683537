import Vue from 'vue';

// import store
import store from '../store';

const momentTz = require('moment-timezone');

Vue.filter('messageTimeFilter', (value) => {
  const { timeZone } = store.getters.getConfJson;
  return momentTz.tz(value, timeZone).format('HH:mm:ss');
});

Vue.filter('openIdFilter', (val) => {
  if (val.length < 8) return val;
  return `${val.slice(0, 8)}...`;
});

Vue.filter('usernameFilter', (val) => {
  if (val.length < 10) return val;
  return `${val.slice(0, 10)}...`;
});

Vue.filter('statusFiler', (val) => {
  if (val == true || val == 1) return 'Active';
  return 'InActive';
});

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('percentageFilter', (value) => {
  if (!value) return '';
  return value.toFixed(2);
});

Vue.filter('avatarInitial', (str) => {
  if (!str) return '';
  if (str != undefined) {
    if (str.includes('-')) {
      const first = str[0];
      const indexOF_ = str.lastIndexOf('-');
      return `${first}${str[indexOF_ + 1]}`.toUpperCase();
    } if (str.includes(' ')) {
      const first = str[0];
      const indexOF_ = str.lastIndexOf(' ');
      return `${first}${str[indexOF_ + 1]}`.toUpperCase();
    }
    return str.substring(0, 2).toUpperCase();
  }
});

Vue.filter('fileSizeFilter', (value) => {
  const mbSize = value / 1024 / 1024;
  if (mbSize >= 1) {
    return `${mbSize.toFixed(2)} MB`;
  }
  const kbSize = value / 1024;
  return `${kbSize.toFixed(2)} KB`;
});


Vue.filter('messageLengthFilter', (val) => {
  if(val){
    if (val.length < 30) return val;
    return `${val.slice(0, 30)}...`;
  }
  return val
});
