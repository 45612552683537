//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from '@/plugins/eventBus';
import timeZone from '@/data/timezone.json';
import * as _ from 'underscore';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'profileBasic',
  data() {
    return {
      notifications: {
        users: false,
        agents: false,
        report: false,
        updates: false,
      },
      delText: '',
      timezone: {
        label: '[GMT+08:00] Perth, Shanghai, Singapore',
        value: 'Asia/Shanghai',
      },
      timeZoneList: timeZone,
      language: {
        label: 'English',
        value: 'en_US',
      },
      languageList: [
        {
          label: 'English',
          value: 'en_US',
        },
        {
          label: 'Nederlands',
          value: 'nl_NL',
        },
        {
          label: '中文',
          value: 'zh_CN',
        },
      ],
      dateRange: {
        label: 'This Month',
        value: 'thisMonth',
      },
      shortcutList: [
        {
          key: 'thisDay',
          label: this.$t('filterBar.calendarShortcuts[0]'),
          value: 'day',
        },
        {
          key: 'lastDay',
          label: this.$t('filterBar.calendarShortcuts[1]'),
          value: '-day',
        },
        {
          key: 'thisWeek',
          label: this.$t('filterBar.calendarShortcuts[2]'),
          value: 'isoWeek',
        },
        {
          key: 'lastWeek',
          label: this.$t('filterBar.calendarShortcuts[3]'),
          value: '-isoWeek',
        },
        {
          key: 'thisMonth',
          label: this.$t('filterBar.calendarShortcuts[4]'),
          value: () => ({
            start: moment().startOf('month'),
            end: moment(),
          }),
        },
        {
          key: 'last3Months',
          label: this.$t('filterBar.calendarShortcuts[5]'),
          value: () => ({
            start: moment()
              .subtract(3, 'months')
              .startOf('month'),
            end: moment()
              .subtract(1, 'months')
              .endOf('month'),
          }),
        },
        {
          key: 'lastYear',
          label: this.$t('filterBar.calendarShortcuts[6]'),
          value: '-year',
        },
      ],
      // update profile
      reset: false,
      passwordType: 'text',
      password: '',
      initialLoad: true,
      emailEdit: false,
      nameEdit: false,
      fileUploaded: false,
      fileSrc: '',
      fileToUpload: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      selectedAIAccount: 'getCurrentAIAccount',
    }),
  },
  methods: {
    ...mapActions({
      updateAdminUser: 'updateAdminUser',
      deleteUser: 'deleteUser',
      userData: 'userData',
      uploadFile: 'uploadFile',
      requestDeleteFile: 'requestDeleteFile',
    }),
    updateProfileData() {
      const that = this;
      if (that.fileUploaded) {
        const file = {
          category: 'image',
          type: 'adminAvatar',
          file: that.fileToUpload,
        };
        const form_data = new FormData();
        for (const key in file) {
          form_data.append(key, file[key]);
        }
        const data = {
          accountId: that.selectedAIAccount.id,
          form_data,
        };
        that.uploadFile(data).then((res) => {
          if (that.currentUser.confJson.hasOwnProperty('avatarResourceId')) {
            that.requestDeleteFile({
              projectId: that.selectedAIAccount.id,
              fileId: that.currentUser.confJson.avatarResourceId,
            });
          }
          that.currentUser.confJson.avatarResourceId = res.resourceId;
          that.updateProfile();
        });
      } else {
        that.updateProfile();
      }
    },
    updateProfile() {
      const that = this;
      const user = that.currentUser;
      if (!that.inValid(user.name)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.nameError'),
        });
      } else if (!that.inValid(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.emailError'),
        });
      } else if (!that.validateEmail(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.invalidEmail'),
        });
      } else {
        const update_Profile = {
          id: that.currentUser.id,
          name: user.name,
          email: user.email,
          confJson: user.confJson,
        };
        update_Profile.confJson.dateRange = that.dateRange.key;
        update_Profile.confJson.language = that.language.value;
        update_Profile.confJson.timeZone = that.timezone.value;
        if (that.reset == true) {
          if (!that.inValid(that.password)) {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: that.$t('userManagement.errors.title'),
              text: that.$t('userManagement.errors.passwordError'),
            });
          } else {
            update_Profile.password = that.password;
            that.updateAdminUser(update_Profile).then((res) => {
              this.$notify({
                group: 'notifications',
                type: 'success',
                title: 'Action Successful',
                text: 'Admin account updated successfully',
              });
              that.userData(update_Profile);
            })
              .catch((err) => {
                this.$notify({
                  group: 'notifications',
                  type: 'error',
                  title: 'Action Error',
                  text: err.errmsg,
                });
              });
          }
        } else {
          that.updateAdminUser(update_Profile).then((res) => {
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action Successful',
              text: 'Admin account updated successfully',
            });
            that.userData(update_Profile);
          })
            .catch((err) => {
              this.$notify({
                group: 'notifications',
                type: 'error',
                title: 'Action Error',
                text: err.errmsg,
              });
            });
        }
      }
    },
    confirmDelete() {
      const that = this;
      if (that.delText == 'DELETE') {
        that.deleteUser(that.currentUser).then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action Successful',
            text: 'Admin account deleted successfully',
          });
        }).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        });
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type DELETE and then hit the delete button',
        });
      }
    },
    generatePassword() {
      const that = this;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      that.passwordType = 'text';
      that.password = result;
    },
    onFilePicked() {
      const that = this;
      const file = event.target.files[0];
      that.fileUploaded = true;
      that.fileSrc = URL.createObjectURL(event.target.files[0]);
      that.fileToUpload = file;
    },
  },
  watch: {
    currentUser: {
      deep: true,
      handler(newVal) {
        const that = this;
        if (newVal !== {} && newVal.confJson !== undefined && that.initialLoad == true) {
          that.language = _.find(
            that.languageList,
            (lang) => lang.value == newVal.confJson.language,
          );
          const dateRange = _.find(that.shortcutList, (shortcut) => shortcut.key == newVal.confJson.dateRange);
          if (dateRange !== undefined) {
            that.dateRange = {
              label: dateRange.label,
              value: dateRange.key,
            };
          }
          that.timezone = _.find(that.timeZoneList, (tz) => tz.value == newVal.confJson.timeZone);
          that.initialLoad = false;
        }
      },
    },
    language: {
      deep: true,
      handler(newVal) {
        this.$i18n.locale = newVal.value;
      },
    },
  },
  created() {
    eventBus.$on('updateMyProfile', this.updateProfileData);
  },
  beforeDestroy() {
    eventBus.$off('updateMyProfile', this.updateProfileData);
  },
  destroyed() {
    eventBus.$off('updateMyProfile', this.updateProfileData);
  },
};
