import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  showLoader: false,
  filterDate: {},
  darkTheme: false,
  locationPrefix: 'conference' //use nbg1 for nbg1-live branch
};
export default {
  state,
  actions,
  mutations,
  getters,
};
