import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  userList: [],
  userDrawer: true,
  profileDrawer: false,
  adminAIAccount: {},
};
export default {
  state,
  actions,
  mutations,
  getters,
};
