//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import * as _ from "underscore";

export default {
  name: "channelDrawer",
  props: {
    actionType: {
      required: true,
      default: "new"
    },
    currentAIProject: {
      required: true,
      type: Object
    },
    currentChannelID: {
      type: Number
    }
  },
  data() {
    return {
      fbSignInParams: {
        scope: "pages_messaging,pages_show_list,pages_manage_metadata",
        return_scopes: true
      },
      fb_pages_list: [],
      showInnerLoading: false,
      createChannelLoading: false,
      updateChannelLoading: false,
      showFacebookBtn: false,
      showFacebookInfo: false,
      currentAIAccount: "",
      boxShadow: "",
      currentChannel: {
        name: "",
        openid: "",
        token: ""
      },
      currentChannelName: "",
      channelList: [
        {
          label: "Facebook Messenger",
          value: "Facebook"
        },
        {
          label: "Wechat",
          value: "wechat",
          inactive: true
        }
      ],
      channelType: "",
      providerLable: "Select your channel",
      pageLabel: "Select a Page",
      delText: "",
      outerWidth: 85
    };
  },
  computed: {
    ...mapGetters({
      selectedAIAccount: "getCurrentAIAccount",
      channelDrawer: "getChannelDrawer",
      AIProjectToEdit: "getAIProjectToEdit",
      darkTheme: "getDarkTheme",
      locationPrefix: 'getLocationPrefix'
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    }
  },

  methods: {
    ...mapActions({
      addNewChannel: "addNewChannel",
      requestChannel: "requestChannel",
      updateChannel: "updateChannel",
      deleteChannel: "deleteChannel"
    }),

    onSignInSuccess(response) {
      const that = this;
      that.showInnerLoading = true;
      if (response && response.status === "connected") {
        if (
          response.authResponse &&
          response.authResponse.accessToken &&
          response.authResponse.userID
        ) {
          const userId = response.authResponse.userID;
          const { accessToken } = response.authResponse;
          // get permanent access token
          const appId = "324257761570012";
          const appSecret = "8c6e6691782c95821642f51c1c0794e7";
          // @todo move the authentication in the backend
          const permanentTokenUrl = `https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${accessToken}`;
          fetch(permanentTokenUrl)
            .then(response => response.json())
            .then(data => {
              if (data && data.access_token) {
                const accessTokenForPage = data.access_token;
                const url = `https://graph.facebook.com/${userId}/accounts?access_token=${accessTokenForPage}`;
                fetch(url)
                  .then(responseIn => responseIn.json())
                  .then(function(dataIn) {
                    if (dataIn && dataIn.data) {
                      const pages = dataIn.data;
                      // add pages to UI
                      if (pages.length > 0) {
                        that.fb_pages_list = pages;
                        that.showInnerLoading = false;
                        that.showFacebookBtn = false;
                        that.currentChannel = {
                          name: "",
                          openid: "",
                          token: ""
                        };
                        that.currentChannelName = "";
                      } else {
                        this.$notify({
                          group: "notifications",
                          type: "error",
                          title: "No Page Selected",
                          text: "Please Select a facebook page to connect"
                        });
                        that.showInnerLoading = false;
                      }
                    }
                  })
                  .catch(() => {
                    console.log("Something went wrong adding the account");
                  });
              }
            })
            .catch(() => {
              console.log("error getting long access token");
            });
        }
      }
    },
    onSignInError(error) {
      const that = this;
      console.log("OH NOES", error);
      that.showInnerLoading = false;
    },
    removePage(page) {
      const that = this;
      that.showInnerLoading = true;
      that.fb_pages_list = _.reject(that.fb_pages_list, uPage => uPage.id == page.id);
      if (that.fb_pages_list.length == 0) {
        that.showFacebookBtn = true;
      }
      that.showInnerLoading = false;
    },
    closeDrawer() {
      setTimeout(() => {
        this.$store.commit("SET_CHANNEL_DRAWER", false);
      }, 100);
    },
    addSubscribeChannel() {
      const that = this;
      that.createChannelLoading = true;
      that.subscribePage(this.currentChannel);
      that.createNewChannel(this.currentChannel);
    },
    // subscribe to the webhook messages
    subscribePage(channel) {
      const that = this;
      const urlWebhook = `https://graph.facebook.com/v8.0/${channel.id}/subscribed_apps?access_token=${channel.access_token}`;
      fetch(urlWebhook, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          subscribed_fields: "messages,messaging_postbacks"
        })
      }).then(responseInIn => responseInIn.json()).then(function(dataInIn) {
          that.$notify({
            group: "notifications",
            type: "success",
            title: "Subscriptions successful",
            text: "Channel's subscribed successfully"
          });
        }).catch(function(err) {
          console.log("Something went wrong subscribing the webhook", err);
          that.$notify({
            group: "notifications",
            type: "error",
            title: "Webhook Error",
            text: "Something went wrong subscribing the webhook"
          });
        });
    },
    // create new channel in API
    createNewChannel(channel) {
      const that = this;
      const data = {
        projectId: that.AIProjectToEdit.id,
        channleData: {
          name: channel.name,
          openid: channel.id,
          token: channel.access_token,
          projectId: that.AIProjectToEdit.id,
          appsecret: "",
          appid: "",
          group_id: 1,
          is_third: true,
          client_class: "LiveCom.LC5G.mod.fbmessenger.client.EvoAiUpdateClient",
          url: `https://${that.locationPrefix}.livecom.cn/evoai/serv/`,
          mediagateway_url: `${that.locationPrefix}.livecom.cn`,
          is_bot: true,
          bot_id: 1,
          extaccess_token: "none",
          is_automessage: false,
          automessage_text: "",
          automessage_interval: 120,
          automessage_expired: 20,
          is_convprechat: false,
          queuePriority: 99,
          confJson: that.currentChannel.confJson
        }
      };
      that.addNewChannel(data).then(res => {
          that.createChannelLoading = false;
          this.$notify({
            group: "notifications",
            type: "success",
            title: "Action successful",
            text: "Channel's Added successfully"
          });
          that.closeDrawer();
        })
        .catch(err => {
          this.$notify({
            group: "notifications",
            type: "error",
            title: "Action Error",
            text: err.errmsg
          });
        });
    },
    fetchChannelData(channelId) {
      const that = this;
      that.showInnerLoading = true;
      that
        .requestChannel({
          channelId,
          projectId: that.AIProjectToEdit.id
        })
        .then(res => {
          that.currentChannel = res.data;
          that.showFacebookInfo = true;
          that.showFacebookBtn = false;
        })
        .finally(() => {
          that.actionType = "edit";
          that.showInnerLoading = false;
        });
    },
    confirmDelete() {
      const that = this;
      if (that.delText == "DELETE") {
        const data = {
          projectId: that.AIProjectToEdit.id,
          channleData: that.currentChannel
        };
        that
          .deleteChannel(data)
          .then(res => {
            this.$notify({
              group: "notifications",
              type: "success",
              title: "Action Successful",
              text: "Channel deleted successfully"
            });
            that.closeDrawer();
          })
          .catch(err => {
            this.$notify({
              group: "notifications",
              type: "error",
              title: "Action Error",
              text: err.errmsg
            });
          });
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          title: "Invalid Data",
          text: "type DELETE and then hit the delete button"
        });
      }
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    channelDrawer(newVal) {
      const that = this;
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.4)";
      } else {
        that.boxShadow = "";
      }
      that.currentChannel = {
        name: "",
        openid: "",
        token: "",
        projectId: "",
        confJson: {}
      };
      that.channelType = "";
      that.currentChannelName = "";
      that.showFacebookBtn = false;
      that.showFacebookInfo = false;
      that.delText = "";
      if (that.actionType == "edit") {
        that.fetchChannelData(that.currentChannelID);
      }
    },
    channelType(newVal) {
      const that = this;
      if (newVal !== "") {
        that.providerLable = "";
        that.showFacebookInfo = true;
        that.showFacebookBtn = true;
      } else {
        that.providerLable = "Select your channel";
        that.showFacebookInfo = false;
        that.showFacebookBtn = false;
      }
    },
    currentChannelName(neval) {
      const that = this;
      if (typeof neval === "object") {
        that.currentChannelName = neval.name;
        that.currentChannel = neval;
        that.pageLabel = "";
      }
    }
  },
  mounted() {
    const that = this;
    that.delText = ""
    if (that.actionType == "edit") {
      that.currentAIAccount = _.find(that.aiAccounts, account => account.id == that.user.accountId);
    } else {
      that.currentAIAccount = _.find(
        that.aiAccounts,
        account => account.id == that.adminAIAccount.id
      );
    }
    window.fbAsyncInit = function() {
      FB.init({
        appId: "324257761570012",
        cookie: true,
        xfbml: true,
        version: "v8.0"
      });
    };
    (function(d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
};
