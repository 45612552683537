import { render, staticRenderFns } from "./createAccount.vue?vue&type=template&id=5e34a880&scoped=true&"
import script from "./createAccount.vue?vue&type=script&lang=js&"
export * from "./createAccount.vue?vue&type=script&lang=js&"
import style0 from "./createAccount.vue?vue&type=style&index=0&id=5e34a880&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e34a880",
  null
  
)

export default component.exports
import {QCard,QInnerLoading,QSpinnerHourglass,QItemSection,QAvatar,QCardSection,QInput,QSelect,QIcon,QPopupProxy,QDate,QBtn,QCheckbox,QList,QItem,QItemLabel,QToggle,QTable,QTr,QTd,QMenu,QDialog,QCardActions,ClosePopup,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QInnerLoading,QSpinnerHourglass,QItemSection,QAvatar,QCardSection,QInput,QSelect,QIcon,QPopupProxy,QDate,QBtn,QCheckbox,QList,QItem,QItemLabel,QToggle,QTable,QTr,QTd,QMenu,QDialog,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup,Ripple})
