export function plugin(store) {
  // subscribe to mutations
  store.subscribe((mutations, state) => {
    // console.log(`mutations ${mutations.type} called with payload , ${mutations.payload} `, mutations)
  });
  // subscribe to acton
  store.subscribeAction({
    before(actions, state) {
      // if (actions.type !== 'toggelLoader')
      //     console.log(`%c actions ${actions.type} was called`, 'background: #0cb2a3; color: #fff; padding: 5px')
      // console.log(`actions ${actions.type} called with payload , ${JSON.stringify(actions.payload)} and before state is ${JSON.stringify(state)}`)
    },
    after(actions, state) {
      // console.log(`actions ${actions.type} called with payload , ${JSON.stringify(actions.payload)} and after state is ${JSON.stringify(state)}`)
    },
  });
}
