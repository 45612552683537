import API from '@/plugins/API';
import nbg1API from '@/plugins/nbg1API';
import * as _ from 'underscore';

export const requestMyProjects = ({ rootState, commit, dispatch }, data) => {
  const { accountId } = rootState.config;
  const evoUser = rootState.auth.id;
  const evoToken = rootState.auth.userToken;
  return new Promise((resolve, reject) => {
    API().get(`/evoai/admin/account/${data.accountId}/config?item=aiProject`).then((res) => {
      
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 0) {
        const aiProjects = [];
        const { orderedKeys } = res.data;
        orderedKeys.forEach(((key, index) => {
          const item = res.data.data[key];
          item.index = index;
          if (item.confJson && item.confJson.avatarResourceId) {
            item.hasAvatar = true;
            item.hasAvatarURL = `https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${item.confJson.avatarResourceId}&project=${accountId}&evouser=${evoUser}&evotoken=${evoToken}`;
          } else {
            item.hasAvatar = false;
          }
          aiProjects.push(item);
        }));
        commit('SET_AI_PROJECTS', aiProjects);
        resolve(aiProjects);
      } else if (res.data.errcode == 40005) {
        dispatch('userLogout');
      } else {
        commit('SET_AI_PROJECTS', []);
        reject(res.data);
      }
    }).catch((err) => {
      reject(err);
    });
  });
};

export const loadAIProject = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/account/${data.accountId}/config?item=aiProject&value=${data.projectId}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      const project = res.data;
      if (!project.confJson.timeZone) {
        project.confJson.timeZone = 'Asia/Shanghai';
      }
      if (!project.confJson.dateRange) {
        project.confJson.dateRange = 'thisMonth';
      }
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});


export const requestProject = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {debugger
  API().get(`/evoai/admin/account/${data.accountId}/config?item=aiProject&value=${data.projectId}`).then((res) => {debugger
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      const project = res.data;
      if (!project.confJson.timeZone) {
        project.confJson.timeZone = 'Asia/Shanghai';
      }
      if (!project.confJson.dateRange) {
        project.confJson.dateRange = 'thisMonth';
      }
      commit('SET_AI_PROJECT_TO_EDIT', res.data);
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const createNewProject = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/account/${data.accountId}/config?item=aiProject&value=0`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const createNewRasaAIVendor = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {debugger
  API().post(`/evoai/admin/account/${data.accountId}/project/${data.projectId}/rasa/config?item=aiProjectIntegr`, data).then((res) => {debugger
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const updateAIProject = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/account/${data.accountId}/config?item=aiProject&value=${data.project.id}`, data.project).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const deleteProject = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiProject&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    //resolve err as backend have delete issue
    resolve();
  });
});

export const requestGoogleURLforAIVendor = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/account/${data.accountId}/config?item=thirdPartyCredentialsFlow&value=Dialogflow&code=${data.code}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const addNewAIVendor = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/project/${data.projectId}/config?item=aiVendor&value=0`, data.vendorData).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const requestAIVendorList = ({ commit, dispatch }, data) => {
  const vendorsList = data.list;
  return new Promise((resolve, reject) => {
    API().get(`/evoai/admin/project/${data.projectId}/config?item=aiVendor`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 0) {
        const aiVendors = [];
        const { orderedKeys } = res.data;
        orderedKeys.forEach(((key, index) => {
          const item = res.data.data[key];
          if (item.aiType.toLowerCase().includes('dialogflow')) {
            item.aiType = 'Dialogflow';
          }
          if (_.contains(vendorsList, item.name) || _.contains(vendorsList, item.openid)) {
            aiVendors.push(item);
          }
        }));
        resolve(aiVendors);
      } else if (res.data.errcode == 40005) {
        dispatch('userLogout');
      } else {
        reject(res.data);
      }
    }).catch((err) => {
      reject(err);
    });
  });
};

export const requestAIVendor = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {debugger
  API().get(`/evoai/admin/project/${data.projectId}/config?item=aiVendorALL&value=${data.id}`).then((res) => {debugger
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      res.data.intentList = res.data.intentList.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      })
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const updateAIVendor = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/project/${data.projectId}/config?item=aiVendor&value=${data.vendorData.id}`, data.vendorData).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const deletAIVendor = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiVendor&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    console.log("err " , err)
    //resolve err as backend have delete issue
    resolve();
  });
});

export const requestChannelList = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  nbg1API().get(`/lcchannel/fbmessenger/adminconfig?projectId=${data.projectId}&dbName=evointegrations&item=channelAccounts`, data.channleData).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      const channels = [];
      const { orderedKeys } = res.data;
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        channels.push(item);
      }));
      resolve(channels);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const addNewChannel = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  nbg1API().post(`/lcchannel/fbmessenger/adminconfig?projectId=${data.projectId}&dbName=evointegrations&item=channelAccounts&value=0`, data.channleData).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const requestChannel = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  nbg1API().get(`/lcchannel/fbmessenger/adminconfig?projectId=${data.projectId}&dbName=evointegrations&item=channelAccounts&value=${data.channelId}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const updateChannel = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  nbg1API().post(`/lcchannel/fbmessenger/adminconfig?projectId=${data.projectId}&dbName=evointegrations&item=channelAccounts&value=${data.channleData.channel_account_id}`, data.channleData).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const deleteChannel = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  nbg1API().get(`/lcchannel/fbmessenger/adminconfig?item=channelAccountsDELETE&projectId=${data.projectId}&dbName=evointegrations&value=${data.channleData.channel_account_id}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});
