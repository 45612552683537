import Vue from 'vue';
// g-tag
import VueGtag from 'vue-gtag';
// Analytics
import VueMatomo from 'vue-matomo';

// facebook login

import FBSignInButton from 'vue-facebook-signin-button';

import Notifications from 'vue-notification';
// import VueCtkDateTimePicker
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// setup vue JSONView component
import JSONView from 'vue-json-component';
import App from './App.vue';

// import store

import './quasar';

// import filters
import './plugins/filters';
import './plugins/mixins';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// import Scss
import './assets/scss/main.scss';

import i18n from './i18n';
import nodata from './components/shared/noData.vue';
import store from './store';
import router from './router';

Vue.component('nodata', nodata);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.use(JSONView);
Vue.use(FBSignInButton);
Vue.use(Notifications);

Vue.config.productionTip = false;



Vue.use(VueMatomo, {
  host: 'https://analytics.evocreations.com/matomo/', // switch this to your account
  // switch this as well you can find the site id after adding the website to the dashboard.
  siteId: 7,

  router,

  enableLinkTracking: true,

  requireConsent: false,

  trackInitialView: true,

  trackerFileName: 'piwik',

  debug: true,
});

Vue.use(VueGtag, {
  config: { id: 'G-WRGXTXGXGL' },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
