//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import * as _ from "underscore";
import moment from "moment";
import aiAccountDrawer from "./aiAccountDrawer";
import createAccount from "./createAccount";

export default {
  components: {
    createAccount,
    aiAccountDrawer
  },
  data() {
    return {
      accountsList: [],
      loading: false,
      filter: "",
      columns: [
        {
          name: "name",
          required: true,
          align: "left",
          label: "ACCOUNT NAME",
          field: row => row.name,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "id",
          align: "center",
          required: true,
          align: "left",
          label: "ACCOUNT ID",
          field: "id",
          sortable: true
        },
        {
          name: "ownerName",
          label: "ACCOUNT OWNER",
          field: "userFullName",
          required: true,
          align: "left",
          sortable: true
        },
        {
          name: "email",
          label: "EMAIL",
          required: true,
          align: "left",
          field: "ownerEmail"
        },
        {
          name: "Status",
          label: "STATUS",
          required: true,
          align: "left",
          field: "statusText"
        },
        {
          name: "Date",
          label: "CREATION DATE",
          required: true,
          align: "left",
          field: "date"
        },
        {}
      ],
      confirm: false, // confirm user delete
      targetUser: {},
      multidelete: false, // delete multi user
      // create/edit user
      actionType: "new",
      AccountToEdit: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
        // rowsNumber: xx if getting data from a server
      }
    };
  },
  computed: {
    ...mapGetters({
      adminAIAccount: "getAdminAIAccount",
      aiAccountDrawer: "getAiAccountDrawer",
      darkTheme: "getDarkTheme"
    }),
    drawerWidth() {
      return Math.floor((85 / 100) * window.innerWidth);
    }
  },
  methods: {
    ...mapActions({
      getActiveAIAccounts: "getActiveAIAccounts"
    }),
    loadAIAccounts() {
      const that = this;
      that.loading = true;
      const data = {
        accountId: that.adminAIAccount.id || 1
      };
      that.getActiveAIAccounts(data).then(res => {
          that.accountsList = _.map(res, account => {
            if (!account.created_time) {
              account.created_time = account.projects[0].created_time;
            }
            if (!account.hasOwnProperty("confJson")) {
              account.confJson = {
                owner: {
                  fname: "",
                  lname: "",
                  email: "",
                  password: ""
                },
                expiryDate: "2021/12/31",
                serverLocation: "China",
                domain: "",
                status: true,
                accountType: "Free",
                historicalDataLimit: 365,
                MAULimit: 10000,
                projectLimit: 10,
                reports: [],
                additionAPI: 2,
                export: true,
                annotations: true,
                calculations: true,
                companyBinding: true,
                agencyAdminView: true,
                systemSetting: {
                  dateRange: "thisMonth",
                  language: "en_US",
                  timeZone: "Asia/Shanghai"
                }
              };
            }
            account.date = moment(account.created_time).format("DD MMM YYYY");
            account.statusText = account.status || "Active";
            account.userFullName = `${account.confJson.owner.fname} ${account.confJson.owner.lname}`;
            account.ownerEmail = account.confJson.owner.email;
            return account;
          });          
        })
        .finally(() => {
          that.loading = false;
        });
    },
    addNewAIAccount() {
      const that = this;
      that.actionType = "new";
      setTimeout(() => {
        this.$store.commit("SET_AI_ACCOUNT_DRAWER", true);
      }, 200);
    },
    toggleEdit(account) {
      const that = this;
      that.AccountToEdit = account;
      that.actionType = "edit";
      setTimeout(() => {
        this.$store.commit("SET_AI_ACCOUNT_DRAWER", true);
      }, 200);
    },   
    confirmDel(user) {
      this.targetUser = user;
      this.confirm = true;
    }
  },
  watch: {
    aiAccountDrawer(newVal) {
      if (newVal == false) {
        this.loadAIAccounts();
      }
    }
  },
  mounted() {
    const that = this;
    this.$store.commit("SET_AI_ACCOUNT_DRAWER", false);
    that.loadAIAccounts();
  }
};
