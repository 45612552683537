import Vue from 'vue';

import './styles/quasar.scss';
import iconSet from 'quasar/icon-set/fontawesome-v5';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import { Quasar, Notify, Loading } from 'quasar';

Vue.use(Quasar, {
  plugins: {
    Notify,
    Loading,
  },
  config: {
    notify: {
    },
  },
  iconSet: iconSet
});
