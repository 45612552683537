import { render, staticRenderFns } from "./drawer.vue?vue&type=template&id=2aa2f702&scoped=true&"
import script from "./drawer.vue?vue&type=script&lang=js&"
export * from "./drawer.vue?vue&type=script&lang=js&"
import style0 from "./drawer.vue?vue&type=style&index=0&id=2aa2f702&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa2f702",
  null
  
)

export default component.exports
import {QDrawer,QAvatar,QBtn,QSpinnerIos,QMenu,QList,QItem,QItemSection,QCard,QInnerLoading,QSpinnerHourglass,QCardSection,QInput,QSelect,QItemLabel,QIcon,QToggle,QTable,QTr,QTd,QCheckbox,ClosePopup,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDrawer,QAvatar,QBtn,QSpinnerIos,QMenu,QList,QItem,QItemSection,QCard,QInnerLoading,QSpinnerHourglass,QCardSection,QInput,QSelect,QItemLabel,QIcon,QToggle,QTable,QTr,QTd,QCheckbox})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup,Ripple})
