import * as _ from 'underscore';
import API from '@/plugins/API';
import router from '@/router/index';
import Storage from '@/plugins/Storage';

export const userLogin = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post('/evoai/admin/login', data).then((res) => {
    if (res.data.errcode == 0) {
      commit('SET_LOGIN_STATUS', true);
      commit('SET_CURRENT_USER', res.data.user);
      commit('SET_AUTH_TOKEN', res.data.evotoken);
      commit('SET_USER_ID', res.data.user.id);
      commit('SET_CONFIG_INFO', res.data.user.confJson);
      commit('SET_ACCOUNT_ID', res.data.user.accountId);
      dispatch('userData', { token: res.data.evotoken, id: res.data.user.id });
      resolve(res.data.user);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const userData = ({ rootState, commit, dispatch }, data) => {
  const { accountId } = rootState.config;
  const evoUser = rootState.auth.id;
  const evoToken = rootState.auth.userToken;
  return new Promise((resolve, reject) => {
    API().get(`/evoai/admin/account/${accountId}/config?item=adminUser&value=${data.id}`).then((res) => {
      if (res.data.errcode === 40005) {
        dispatch('userLogout');
      } else {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        const user = res.data;
        user.confJson.timeZone = user.confJson.timeZone || 'Asia/Shanghai';
        if (user.confJson.timeZone == 'undefined') {
          user.confJson.timeZone = 'Asia/Shanghai';
        }
        if (user.confJson.avatarResourceId) {
          user.hasAvatar = true;
          //project and account id conflict from backend
          user.hasAvatarURL = `https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${user.confJson.avatarResourceId}&project=${accountId}&evouser=${evoUser}&evotoken=${evoToken}`;
        } else {
          user.hasAvatar = false;
        }
        user.confJson.language = user.confJson.language || 'en_US';
        user.confJson.dateRange = user.confJson.dateRange || 'thisMonth';
        user.role = user.confJson.roles[0];
        if (user.role == '*') {
          user.role = 'Superuser';
        } else {
          user.role = user.role.charAt(0).toUpperCase() + user.role.slice(1);
        }
        commit('SET_LOGIN_STATUS', true);
        commit('SET_CURRENT_USER', user);
        if (data.token) {
          commit('SET_AUTH_TOKEN', data.token);
        }
        commit('SET_USER_ID', res.data.id);
        commit('SET_CONFIG_INFO', user.confJson);
        commit('SET_ACCOUNT_ID', res.data.accountId);
        resolve(res.data);
      }
    });
  });
};

export const userLogout = ({ commit }) => new Promise((resolve, reject) => {
  API().get('/evoai/admin/logout').then((res) => {
    commit('SET_LOGIN_STATUS', false);
    commit('SET_CURRENT_USER', {});
    commit('SET_AUTH_TOKEN', '');
    commit('SET_USER_ID', '');
    commit('SET_CONFIG_INFO', {});
    commit('SET_ACCOUNT_ID', '');
    setTimeout(() => {
      let theme = Storage.getItem('theme') || 'white'
      Storage.clearAll();
      Storage.setItem('theme' , theme)
    }, 100);
    resolve(res);
    setTimeout(() => {
      if (router.history.current.name !== 'login') {
        router.push('/welcome');
      }
    }, 200);
  });
});
