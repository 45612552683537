//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import eventBus from '@/plugins/eventBus'
import * as _ from 'underscore'

const today = moment()

export default {
  name: 'filterBar',
  data() {
    return {
      title: 'AI Sessions',
      language: this.$t('filterBar.languages[0]'),
      channel: this.$t('filterBar.channels[0]'),
      currentShortut: '',
      date: {
        start: moment().subtract(7, 'd').format('DD-MM-YYYY'),
        shortcut: '',
        end: today.format('DD-MM-YYYY'),
      },
    }
  },
  computed: {
    ...mapGetters({
      accountId: 'getAccountId',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme',
    }),
    lang() {
      return this.$t('lang')
    },
    baseURl() {
      return window.location.href
    },
    customShortcuts() {
      return [
        {
          key: 'thisDay',
          label: this.$t('filterBar.calendarShortcuts[0]'),
          value: 'day',
        },
        {
          key: 'lastDay',
          label: this.$t('filterBar.calendarShortcuts[1]'),
          value: '-day',
        },
        {
          key: 'thisWeek',
          label: this.$t('filterBar.calendarShortcuts[2]'),
          value: 'isoWeek',
        },
        {
          key: 'lastWeek',
          label: this.$t('filterBar.calendarShortcuts[3]'),
          value: '-isoWeek',
        },
        {
          key: 'thisMonth',
          label: this.$t('filterBar.calendarShortcuts[4]'),
          value: () => ({
            start: moment().startOf('month'),
            end: moment(),
          }),
        },
        {
          key: 'last3Months',
          label: this.$t('filterBar.calendarShortcuts[5]'),
          value: () => ({
            start: moment().subtract(90, 'days'),
            end: moment(),
          }),
        },
        {
          key: 'lastYear',
          label: this.$t('filterBar.calendarShortcuts[6]'),
          value: '-year',
        },
      ]
    },
    TimeZone() {
      return this.currentUser?.confJson?.timeZone
    },
  },
  methods: {
    ...mapActions({
      setAutomaticDate: 'setAutomaticDate',
    }),
    checkOpen(status) {},
    onUpdateDateFilter(val) {
      const that = this
      that.date = {
        start: moment(val.start_date, 'DD MMM YYYY').format('DD-MM-YYYY'),
        end: moment(val.end_date, 'DD MMM YYYY').format('DD-MM-YYYY'),
      }
    },
    copyLink() {
      const that = this
      const testingCodeToCopy = document.querySelector('#baseURl')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()
      try {
        const successful = document.execCommand('copy')
        this.$q.notify({
          progress: true,
          type: 'positive',
          caption: that.$t('filterBar.linkCopied'),
          timeout: 1000,
          position: 'top',
        })
      } catch (err) {
        alert('Oops, unable to copy')
      }
      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
  watch: {
    date(newVal, oldVal) {
      const that = this
      if (newVal) {
        const { shortcut } = newVal
        if (shortcut !== undefined) {
          that.currentShortut = that.customShortcuts.find(
            (record) => record.value == shortcut,
          ).label
        } else {
          that.currentShortut = undefined
        }
        if (that.currentUser.role == 'Reporter') {
          if (
            that.date.start > moment().subtract(8, 'd').format('DD-MM-YYYY')
          ) {
            that.date.shortcut = that.currentShortut
            that.setAutomaticDate(that.date)
            eventBus.updateDate(that.date)
          } else {
             that.date.start = ""
;            this.$q.notify({
              progress: true,
              type: 'negative',
              caption: that.$t('Ivalid Date! , Select Date upto last 15 days'),
              timeout: 2000,
              position: 'top',
            })
          }
        } else {
          that.date.shortcut = that.currentShortut
          that.setAutomaticDate(that.date)
          eventBus.updateDate(that.date)
        }
      }
    },
    $route(to) {
      this.title = to.meta.title
    },
    lang(newVal) {
      if (newVal) {
        this.language = this.$t('filterBar.languages[0]')
        this.channel = this.$t('filterBar.channels[0]')
      }
    },
  },
  mounted() {
    const that = this
    that.setAutomaticDate(that.date)
    eventBus.updateDate(that.date)
  },
  created() {
    eventBus.$on('updateDateFilter', this.onUpdateDateFilter)
  },
  destroyed() {
    eventBus.$off('updateDateFilter', this.onUpdateDateFilter)
  },
}
