//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from '@/plugins/eventBus';
import { mapGetters, mapActions } from 'vuex';
import createUser from './createUser';
import userDrawer from './userDrawer';
import * as _ from "underscore";

export default {
  components: {
    createUser,
    userDrawer,
  },
  data() {
    return {
      userList: [],
      loading: false,
      filter: '',
      selectedUsers: [],
      columns: [
        {
          name: 'name',
          required: true,
          align: 'left',
          label: 'NAME',
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: 'Email',
          align: 'center',
          required: true,
          align: 'left',
          label: 'EMAIL',
          field: 'email',
          sortable: true,
        },
        {
          name: 'role',
          label: 'ROLE',
          field: 'role',
          required: true,
          align: 'left',
          sortable: true,
        },
        {
          name: 'status',
          label: 'STATUS',
          required: true,
          align: 'left',
          field: 'status',
        },
        {
          name: 'Date',
          label: 'DATE',
          required: true,
          align: 'left',
          field: 'date',
        },
        {},
      ],
      confirm: false, // confirm user delete
      targetUser: {},
      multidelete: false, // delete multi user
      // create/edit user
      actionType: 'new',
      userToEdit: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        // rowsNumber: xx if getting data from a server
      },
      currentAIAccount: '',
      message: 'No user found......',
      currentAIAccountId: '',
      outerWidth: 85,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      aiAccounts: "getAIAccounts",
      adminAIAccount: "getAdminAIAccount",
      selectedAIAccount: "getCurrentAIAccount",
      userDrawer: 'getUserDrawer',
      darkTheme: 'getDarkTheme',
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    }
  },
  methods: {
    ...mapActions({
      listAllUsers: 'listAllUsers',
      deleteUser: 'deleteUser',
    }),
    loadUsers() {
      const that = this;
      if(this.currentUser.role == 'Admin' || this.currentUser.role == 'Superuser'){
        that.loading = true;
        const data = {
          accountId: that.currentAIAccountId,
        };
        that.listAllUsers(data).then((res) => {
          that.userList = res;
          that.loading = false;
          console.log(that.userList);
        });
      }
    },
    addNewUser() {
    console.log("Reached on Add User");
      const that = this;
      that.actionType = 'new';
      setTimeout(() => {
        this.$store.commit('SET_USER_DRAWER', true);
      }, 200);
    },
    toggleEdit(user) {
      const that = this;
      that.userToEdit = user;
      that.actionType = 'edit';
      setTimeout(() => {
        this.$store.commit('SET_USER_DRAWER', true);
      }, 200);
    },
    confirmDel(user) {
      this.targetUser = user;
      this.confirm = true;
    },
    delUser() {
      const that = this;
      const { targetUser } = that;
      that.deleteUser(targetUser).then((res) => {
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Action Successful',
          text: 'Admin account deleted successfully',
        });
        that.targetUser = {};
        that.loadUsers();
      })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        });
    },
    deleteMultiple() {
      const that = this;
      const { selectedUsers } = that;
      selectedUsers.forEach((user) => {
        that.deleteUser(user).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        });
      });
      this.$notify({
        group: 'notifications',
        type: 'success',
        title: 'Action Successful',
        text: "All selected account's deleted successfully",
      });
      that.loadUsers();
      that.selectedUsers = [];
    },
    toggleDrawer() {
      const that = this;
      this.$store.commit('SET_USER_DRAWER', false);
      that.loadUsers()
    },
  },
  watch: {
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
      if(this.currentUser.role == 'Admin' || this.currentUser.role == 'Superuser'){
        that.currentAIAccount = newVal[0];
        that.currentAIAccountId = that.currentAIAccount.id
      }
    }
    },
    currentAIAccount(newVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.currentAIAccountId = newVal.id;
        }
        that.loadUsers();
      }
    },
    userDrawer(newVal) {
      const that = this;
      this.loadUsers();
    },
  },
  created() {
    eventBus.$on("triggerAccountDrawer", this.toggleDrawer);
  },
  beforeDestroy() {
    eventBus.$off("triggerAccountDrawer", this.toggleDrawer);
  },
  destroyed() {
    eventBus.$off("triggerAccountDrawer", this.toggleDrawer);
  }
};
