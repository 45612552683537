//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import * as _ from "underscore";
import basic from "./user/basic";

export default {
  name: "accountDrawer",
  components: {
    basic
  },
  data() {
    return {
      showDrawer: false,
      updateUserLoading: false,
      outerWidth: 85,
      boxShadow: ""
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      accountId: "getAccountId",
      adminAIAccount: "getAdminAIAccount",
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    }
  },
  methods: {
    ...mapActions({
      userData: "userData"
    }),
    toggleDrawer(data) {
      this.getUserData();
      this.showDrawer = data.status;
    },
    closeDrawer() {
      if (this.userDrawer == true) {
        this.$store.commit("SET_USER_DRAWER", false);
      }
      eventBus.triggerAccountDrawer(false);
      this.showDrawer = false;
    },
    saveNewUser() {
      eventBus.createUser();
    },
    updateCurrentUser() {
      eventBus.updateUser();
    },
    updateMyProfile() {
      const that = this;
      that.updateUserLoading = true;
      eventBus.updateMyProfile();
      setTimeout(() => {
        that.updateUserLoading = false;
      }, 1000);
    },
    getUserData() {
      const that = this;
      const userToken = this.$store.getters.getUserToken;
      const userId = this.$store.getters.getUserId;
      that.userData({ token: userToken, id: userId });
    }
  },
  watch: {
    showDrawer(newVal) {
      const that = this;
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.4)";
      } else {
        that.boxShadow = "";
      }
    }
  },
  created() {
    eventBus.$on("triggerProfileDrawer", this.toggleDrawer);
  },
  beforeDestroy() {
    eventBus.$off("triggerProfileDrawer", this.toggleDrawer);
  },
  destroyed() {
    eventBus.$off("triggerProfileDrawer", this.toggleDrawer);
  }
};
