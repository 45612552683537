import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  aiAccounts: [],
  currentAIAccount: {
    configuration:{
    }
  },
  requestType: 'account',
  currentAIProject: {
    configuration:{ 
    }
  },
  accountId: '',
  projectId: '',
  aiAccountDrawer: false,
};
export default {
  state,
  actions,
  mutations,
  getters,
};
