//
//
//
//
//
//
//
//
//

export default {
  props: {
    bgColor: {
      default: '#fffff',
    },
  },
};
