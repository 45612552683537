import { render, staticRenderFns } from "./channels.vue?vue&type=template&id=8de42436&scoped=true&"
import script from "./channels.vue?vue&type=script&lang=js&"
export * from "./channels.vue?vue&type=script&lang=js&"
import style0 from "./channels.vue?vue&type=style&index=0&id=8de42436&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8de42436",
  null
  
)

export default component.exports
import {QDrawer,QAvatar,QMenu,QList,QItem,QItemSection,QCard,QInnerLoading,QSpinnerHourglass,QCardSection,QSelect,QItemLabel,QBtn,QSpinnerIos,QInput,QCardActions,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDrawer,QAvatar,QMenu,QList,QItem,QItemSection,QCard,QInnerLoading,QSpinnerHourglass,QCardSection,QSelect,QItemLabel,QBtn,QSpinnerIos,QInput,QCardActions,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
