import Vue from 'vue';

Vue.mixin({
  methods: {
    inValid(value) {
      if (value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    toHHMMSS(secs, resType) {
      const sec_num = parseInt(secs, 10);
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor(sec_num / 60) % 60;
      const seconds = sec_num % 60;
      if (resType == 'parsed') {
        if (hours > 0) {
          return `${hours}h ${minutes}' ${seconds}"`;
        }
        return `${minutes}' ${seconds}"`;
      } if (resType == 'string') {
        if (hours > 0) {
          return `${hours}h ${minutes}min ${seconds} sec`;
        } if (minutes > 0) {
          return `${minutes}min ${seconds} sec`;
        }
        return `${seconds} seconds`;
      }
      return [hours, minutes, seconds].map((v) => (v < 10 ? `0${v}` : v)).filter((v, i) => v !== '00' || i > 0).join(':');
    },
  },
});
