import API from '@/plugins/API';
import * as _ from 'underscore';

export const generateXMLFile = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  const evoUser = rootState.auth.id;
  const evoToken = rootState.auth.userToken;
  window.open(`https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/generateXLS.jsp?item=${data.type}&startdate=${data.startdate}&enddate=${data.enddate}&aiAccountId=${data.aiAccount}&evotoken=${evotoken}&evouser=${evouser}`, '_blank');
});

export const listAllFiles = ({ commit, dispatch }, data) =>{
  return  new Promise((resolve, reject) => {
    API().get(`/evoai/admin/project/${data.projectId}/config?item=uploadedFile`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      } else {
        resolve(res.data)
      }
    }).catch((err) => {
      reject(err);
    });
  });
  
}
export const uploadFile = ({ commit, dispatch }, data) => {
  return new Promise((resolve, reject) => {
    API().post(`/evoai/admin/project/${data.accountId}/config?item=file&value=0`, data.form_data).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      } else {
        resolve(res.data);
      }
    }).catch((err) => {
      reject(err);
    });
  });
}

export const requestFileInfo = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/project/${data.accountId}/config?item=uploadedFile&value=${data.fileId}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      resolve(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const requestDeleteFile = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/project/${data.projectId}/config?item=uploadedFile&value=${data.fileId}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      resolve(res.data);
    }
  }).catch((err) => {
    //resolve err as backend have delete issue
    resolve();
  });
});

export const getFileData = ({ rootState, commit, dispatch }, data) => {
  const evoUser = rootState.auth.id;
  const evoToken = rootState.auth.userToken;
  return new Promise((resolve, reject) => {
    fetch(`https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${data.resourceId}&project=${data.accountId}&evouser=${evoUser}&evotoken=${evoToken}`).then((response) => response.json()).then((data) => {
      resolve(data);
    });
  });
};
