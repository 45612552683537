module.exports = {
  SET_AI_PROJECTS: (state, list) => {
    state.projects = list;
  },
  SET_AIVENDORS_DRAWER: (state, status) => {
    state.AIVendorsDrawer = status;
  },
  SET_AIPROJECT_ACCOUNT: (state, obj) => {
    state.aiProjectAccount = obj;
  },
  SET_AI_PROJECT_TO_EDIT: (state, obj) => {
    state.aiProjectToEdit = obj;
  },
  SET_CHANNEL_DRAWER: (state, status) => {
    state.channelDrawer = status;
  },
  //project and account id's for drawers
  SET_EDIT_SOURCE: (state , obj) => {
    state.editSource = obj
  }
};
