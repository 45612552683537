import Vue from 'vue';

export default new Vue({
  methods: {
    updateDate(val) {
      this.$emit('updateDate', val);
    },
    updateDateFilter(val) {
      this.$emit('updateDateFilter', val);
    },
    updateAIAccount(val) {
      this.$emit('updateAIAccount', val);
    },
    updateAIProject(val) {
      this.$emit('updateAIProject', val);
    },
    triggerAccountDrawer(val) {
      this.$emit('triggerAccountDrawer', val);
    },
    triggerProfileDrawer(val) {
      this.$emit('triggerProfileDrawer', val);
    },
    createAIAccount() {
      this.$emit('createAIAccount');
    },
    updateCurrentAIAccount() {
      this.$emit('updateCurrentAIAccount');
    },
    createUser(accountId) {
      this.$emit('createUser', accountId);
    },
    updateUser(accountId) {
      this.$emit('updateUser', accountId);
    },
    updateMyProfile() {
      this.$emit('updateMyProfile');
    },
    triggerProjectDrawer(val) {
      this.$emit('triggerProjectDrawer', val);
    },
  },

});
