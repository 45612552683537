//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

export default {
  props:{
    customMsg:{
      Type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      aiProjects: 'getMyProjects',
      aiAccounts: 'getAIAccounts',
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    openEditProjectDrawer() {
      const that = this;
      eventBus.triggerProjectDrawer({
        status: true,
        actionType: 'edit',
        projectId: that.currentAIAccount.id,
      });
    },
  }
};
