//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import * as _ from "underscore";
import createAccount from "./createAccount";

export default {
  name: "userDrawer",
  components: {
    createAccount
  },
  props: {
    actionType: {
      required: true,
      default: "new"
    },
    AccountToEdit: {
      required: true
    }
  },
  data() {
    return {
      showDrawer: false,
      currentAIAccount: "",
      currentAIAccountId: "",
      eidtAccountLoading: false,
      addAccountLoading: false,
      boxShadow: "",
      aiAccount: {}
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      accountId: "getAccountId",
      aiAccounts: "getAIAccounts",
      aiAccountDrawer: "getAiAccountDrawer"
    }),
    drawerWidth() {
      return Math.floor((85 / 100) * window.innerWidth);
    }
  },
  methods: {
    closeDrawer() {
      setTimeout(() => {
        this.$store.commit("SET_AI_ACCOUNT_DRAWER", false);
        this.showDrawer = false;
      }, 100);
    },
    createNewAIAccount() {
      const that = this;
      that.addAccountLoading = true;
      eventBus.createAIAccount();
      setTimeout(() => {
        that.addAccountLoading = false;
      }, 1000);
    },
    updateCurrentAccount() {
      const that = this;
      that.eidtAccountLoading = true;
      eventBus.updateCurrentAIAccount();
      setTimeout(() => {
        that.eidtAccountLoading = false;
      }, 1000);
    }
  },
  watch: {
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
        that.currentAIAccount = _.find(newVal, account => account.id == that.accountId);
      }
    },
    currentAIAccount(newVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.currentAIAccountId = newVal.id;
        }
      }
    },
    aiAccountDrawer(newVal) {
      const that = this;
      that.eidtAccountLoading = false;
      that.addAccountLoading = false;
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.4)";
      } else {
        that.boxShadow = "";
      }
    }
  }
};
