//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import * as _ from "underscore";
import createUser from "./createUser";

export default {
  name: "userDrawer",
  components: {
    createUser
  },
  props: {
    actionType: {
      required: true,
      default: "new"
    },
    userToEdit: {
      required: true
    },
    accountId:{
      required: true
    }
  },
  data() {
    return {
      showDrawer: false,
      currentAIAccount: "",
      currentAIAccountId: "",
      eidtUserLoading: false,
      addUserLoading: false,
      boxShadow: "",
      userAccount: {},
      outerWidth: 85,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      aiAccounts: "getAIAccounts",
      adminAIAccount: "getAdminAIAccount",
      userDrawer: "getUserDrawer"
    }),
   
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    }
  },
  methods: {
    closeDrawer() {
      setTimeout(() => {
        this.$store.commit("SET_USER_DRAWER", false);
        this.showDrawer = false;
      }, 100);
    },
    saveNewUser() {
      const that = this;
      that.addUserLoading = true;
      eventBus.createUser(this.currentAIAccountId);
      setTimeout(() => {
        that.addUserLoading = false;
      }, 1000);
    },
    updateCurrentUser() {
      const that = this;
      that.eidtUserLoading = true;
      eventBus.updateUser(this.accountId);
      setTimeout(() => {
        that.eidtUserLoading = false;
      }, 1000);
    },
  },
  watch: {
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
        that.currentAIAccount = _.find(newVal, account => account.id == that.accountId);
      }
    },
    currentAIAccount(newVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.currentAIAccountId = newVal.id;
        }
      }
    },
    userToEdit(newVal) {
      const that = this;
      that.userAccount = _.find(that.aiAccounts, account => account.id == newVal.accountId);
      that.currentAIAccount = _.find(that.aiAccounts, account => account.id == newVal.accountId);
    },
    userDrawer(newVal) {
      const that = this;
      that.eidtUserLoading = false;
      that.addUserLoading = false;
      that.userAccount = _.find(that.aiAccounts, account => account.id == that.accountId);
      that.currentAIAccount = _.find(that.aiAccounts, account => account.id ==  that.accountId);
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.4)";
      } else {
        that.boxShadow = "";
      }
    }
  },
  mounted() {
    console.log(this.aiAccounts, 'mounted');
    const that = this;
    if (that.actionType == "edit") {
      that.currentAIAccount = _.find(that.aiAccounts, account => account.id == that.user.accountId);
    } else {
      that.currentAIAccount = _.find(that.aiAccounts, account => account.id == that.accountId);
    }
  }
};
