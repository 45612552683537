//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
import { mapGetters, mapActions } from "vuex";
import timeZone from "@/data/timezone.json";
import industries from "@/data/industries.json";
import * as _ from "underscore";
import aiVendorDrawer from "./integration/aiVendors";
import channelDrawer from "./integration/channels";

export default {
  name: "projectDrawer",
  components: {
    aiVendorDrawer,
    channelDrawer
  },
  props: {
    showDrawer: {      
      required: true,
      default: false,
    },
    fromWhere: {      
      required: true,
    },
  },
  data() {
    return {
      timeZoneList: timeZone,
      industriesList: industries,
      timezone: {
        label: "[GMT+08:00] Perth, Shanghai, Singapore",
        value: "Asia/Shanghai"
      },
      dateRange: {
        label: "This Month",
        value: "thisMonth"
      },
      shortcutList: [
        {
          key: "thisDay",
          label: this.$t("filterBar.calendarShortcuts[0]"),
          value: "day"
        },
        {
          key: "lastDay",
          label: this.$t("filterBar.calendarShortcuts[1]"),
          value: "-day"
        },
        {
          key: "thisWeek",
          label: this.$t("filterBar.calendarShortcuts[2]"),
          value: "isoWeek"
        },
        {
          key: "lastWeek",
          label: this.$t("filterBar.calendarShortcuts[3]"),
          value: "-isoWeek"
        },
        {
          key: "thisMonth",
          label: this.$t("filterBar.calendarShortcuts[4]"),
          value: () => ({
            start: moment().startOf("month"),
            end: moment()
          })
        },
        {
          key: "last3Months",
          label: this.$t("filterBar.calendarShortcuts[5]"),
          value: () => ({
            start: moment().subtract(3, "months").startOf("month"),
            end: moment().subtract(1, "months").endOf("month")
          })
        },
        {
          key: "lastYear",
          label: this.$t("filterBar.calendarShortcuts[6]"),
          value: "-year"
        }
      ],
      showDrawer: false,
      createProjectLoading: false,
      showInnerLoading: false,
      showInnerLoadingAIVendor: false,
      outerWidth: 85,
      boxShadow: "",
      actionType: "new",
      currentProject: {
        name: "",
        statue: "",
        confJson: {
          channels: [],
          industry: "",
          defaultProject: true,
          channelCount: 0,
          topicSeparator: '.'
        }
      },
      toggleStatus: true,
      delText: "",
      aiVendorActionType: "new",
      currentAIVendorID: 0,
      channelActionType: "new",
      currentChannelID: 0,
      enviroments: [
        {
          label: "Live",
          value: "Live"
        },
        {
          label: "Staging",
          value: "Staging"
        },
        {
          label: "Test",
          value: "Test"
        }
      ],
      enviromentLabel: "is your bot live or in testing phase?",
      industryLabel:"what industry is your chatbot serving?",
      fileUploaded: false,
      fileSrc: "",
      fileToUpload: null,
      userList: [],
      userAvailableList: [],
      userToAdd: '',
      selectedUsers: [],
      filter: '',
      columns: [
        {
          name: 'name',
          required: true,
          align: 'left',
          label: 'NAME',
          field: 'name',
          sortable: true,
        },
        {
          name: 'Email',
          align: 'center',
          required: true,
          align: 'left',
          label: 'EMAIL',
          field: 'email',
          sortable: true,
        },
        {
          name: 'role',
          label: 'ROLE',
          field: 'role',
          required: true,
          align: 'left',
          sortable: true,
        }
      ],
      tableLoading: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        // rowsNumber: xx if getting data from a server
      },
      outerWidth: 85
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      accountId: "getAccountId",
      selectedAIAccount: "getCurrentAIAccount",
      aiAccounts: "getAIAccounts",
      adminAIAccount: "getAdminAIAccount",
      aiVendorsDrawer: "getAIVendorsDrawer",
      channelDrawer: "getChannelDrawer",
      darkTheme: "getDarkTheme",
      locationPrefix: 'getLocationPrefix',
      editSource: "getEditSource"
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    },
    avatarInitial() {
      const str = this.currentProject.name;
      if (str != undefined) {
        if (str.includes("-")) {
          const first = str[0];
          const indexOF_ = str.lastIndexOf("-");
          return `${first}${str[indexOF_ + 1]}`.toUpperCase();
        }
        return str.substring(0, 2).toUpperCase();
      }
    }
  },
  methods: {
    ...mapActions({
      createNewProject: "createNewProject",
      getActiveAIAccounts: "getActiveAIAccounts",
      requestProject: "requestProject",
      updateAIProject: "updateAIProject",
      deleteProject: "deleteProject",
      requestAIVendorList: "requestAIVendorList",
      requestChannelList: "requestChannelList",
      uploadFile: "uploadFile",
      requestDeleteFile: "requestDeleteFile",
      listAllUsers: 'listAllUsers',
      updateAdminUser: 'updateAdminUser'
    }),
    toggleDrawer(data) {
      const that = this;
      this.showDrawer = data.status;
      this.actionType = data.actionType;
      if (data.actionType == "edit") {
        that.loadCurrentProject(data.projectId ,'toggleDrawer' );
      } else {
        that.currentProject = {
          name: "",
          confJson: {
            channels: [],
            industry: "",
            environment: "",
            defaultProject: false
          }
        };
      }
    },
    closeDrawer() { debugger        
        if(this.fromWhere && this.fromWhere == 'CreateAccount') {
          this.$parent.hideProjectDrawer();
        }
        this.showDrawer = false;
    },
    loadCurrentProject(projectId , ref) {debugger
      console.log("projectId " , projectId , ref)
      const that = this;
      that.showInnerLoading = true;
      that.showInnerLoadingAIVendor = true;
      const accountId  = this.$store.state.config.accountId;
      const evoUser = this.$store.state.auth.id;
      const evoToken = this.$store.state.auth.userToken;
      const data = {
        accountId: that.selectedAIAccount.id,
        projectId: projectId || that.editSource.projectId
      };
      that.requestProject(data).then(res => {debugger
        // reqeuest project info
        const currentProject = res;
        if (currentProject.confJson.hasOwnProperty("avatarResourceId")) {
          currentProject.hasAvatar = true;
          currentProject.hasAvatarURL = `https://${this.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${currentProject.confJson.avatarResourceId}&project=${that.selectedAIAccount.accountId}&evouser=${evoUser}&evotoken=${evoToken}`;
        } else {
          currentProject.hasAvatar = false;
        }
        if (!currentProject.confJson.hasOwnProperty("defaultProject")) {
          // default project check
          currentProject.confJson.defaultProject = false;
        }
        if (!currentProject.confJson.hasOwnProperty("topicSeparator")) {
          // default topic separator
          currentProject.confJson.topicSeparator = '.';
        }
        if (!currentProject.confJson.hasOwnProperty("channelCount")) {
          // Total Channel Count
          currentProject.confJson.channelCount = 0;
        }
        that.delText = "";
        that.currentProject = currentProject;
        if (currentProject.vendorlist !== "") {
          // check if project have vendors
          that.requestAIVendorList({
              projectId: currentProject.id,
              list: currentProject.vendorlist.split("+")
            }).then(aiVendorRes => {
              currentProject.aiVendorlist = aiVendorRes;
              return that.requestChannelList({
                // request project channel
                projectId: currentProject.id
              });
            }).then(channelResp => {
              currentProject.channelList = channelResp;
              currentProject.confJson.channelCount = channelResp.length;
              that.currentProject = currentProject;
              if (channelResp.length !== currentProject.confJson.channelCount) {
                currentProject.confJson.channelCount = channelResp.length;
                that.updateCurrentProject(false);
              }
            });
        } else {
          that.requestChannelList({projectId: currentProject.id}).then(channelResp => {
              currentProject.channelList = channelResp;
              currentProject.confJson.channelCount = channelResp.length;
              that.currentProject = currentProject;
              if (channelResp.length !== currentProject.confJson.channelCount) {
                currentProject.confJson.channelCount = channelResp.length;
                that.updateCurrentProject(false);
              }
            });
          that.currentProject = currentProject;
          setTimeout(() => {
            that.showInnerLoading = false;
            that.showInnerLoadingAIVendor = false;
          }, 1000);
        }
        setTimeout(() => {
          that.showInnerLoading = false;
          that.showInnerLoadingAIVendor = false;
          if(this.currentUser.role == 'Admin' || this.currentUser.role == 'Superuser'){
            setTimeout(() => {
              that.loadUsers()
            }, 300);
          }
        }, 1000);
      });
    },
    createProject() {
      const that = this;
      that.createProjectLoading = true;
      that.showInnerLoading = true;
      that.showInnerLoadingAIVendor = true;
      if (that.isValid(that.currentProject.name)) {
        const data = {
          accountId: that.selectedAIAccount.id,
          name: that.currentProject.name,
          openid: `project_${that.selectedAIAccount.id}_${that.currentProject.name}_${new Date().getTime()}`,
          confJson: that.currentProject.confJson
        };
        data.confJson.dateRange = that.dateRange.key;
        data.confJson.timeZone = that.timezone?.value || 'Asia/Shanghai';
        delete data.confJson.channels;
        that.createNewProject(data).then(res => {
            this.$notify({
              group: "notifications",
              type: "success",
              title: "Action Successful",
              text: `New Project ${data.name} Added`
            });
            that.loadCurrentProject(res.data.resourceId , 'create');
            that.actionType = "edit";
          }).catch(err => {
            this.$notify({
              group: "notifications",
              type: "error",
              title: "Action Error",
              text: err.errmsg
            });
          }).finally(() => {
            that.createProjectLoading = false;
            that.showInnerLoading = false;
            that.showInnerLoadingAIVendor = false;
          });
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          title: "Invalid Data",
          text: "Project Name Required"
        });
        that.createProjectLoading = false;
        that.showInnerLoading = false;
        that.showInnerLoadingAIVendor = false;
      }
    },
    checkProjectUpdateStatus() {
      const that = this;
      that.createProjectLoading = true;
      that.showInnerLoading = true;
      that.showInnerLoadingAIVendor = true;
      if (that.fileUploaded == true) {
        const file = {
          category: "image",
          type: "projectAvatar",
          file: that.fileToUpload
        };
        const form_data = new FormData();
        for (const key in file) {
          form_data.append(key, file[key]);
        }
        const data = {
          accountId: that.currentProject.accountId,
          form_data
        };
        that.uploadFile(data).then(res => {
          if (that.currentProject.confJson.hasOwnProperty("avatarResourceId")) {
            that.requestDeleteFile({
              projectId: that.currentProject.id,
              fileId: that.currentProject.confJson.avatarResourceId
            });
          }
          that.currentProject.confJson.avatarResourceId = res.resourceId;
          that.updateCurrentProject();
        });
      } else {
        that.updateCurrentProject();
      }
    },
    updateCurrentProject(notify = true) {
      const that = this;
      if (that.isValid(that.currentProject.name)) {
        if (typeof that.currentProject.confJson.environment === "object") {
          that.currentProject.confJson.environment = that.currentProject.confJson.environment.value;
        }
        that.currentProject.confJson.dateRange = that.dateRange.key;
        that.currentProject.confJson.timeZone = that.timezone.value;
        delete that.currentProject.confJson.channels;
        that.currentProject.status = "";
        that.updateAIProject({
            accountId: that.currentProject.accountId,
            project: that.currentProject
          }).then(res => {
            if (notify) {
              this.$notify({
                group: "notifications",
                type: "success",
                title: "Action Successful",
                text: `Project ${that.currentProject.name} Updated`
              });
            }
          }).catch(err => {
            this.$notify({
              group: "notifications",
              type: "error",
              title: "Action Error",
              text: err.errmsg
            });
          }).finally(() => {
            that.createProjectLoading = false;
            that.showInnerLoading = false;
            that.showInnerLoadingAIVendor = false;
          });
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          title: "Invalid Data",
          text: "Project Name Required"
        });
        that.createProjectLoading = false;
        that.showInnerLoading = false;
        that.showInnerLoadingAIVendor = false;
      }
    },
    loadUsers() {
      const that = this;
      that.tableLoading = true;
      let currentProjectId =  that.currentProject.id || this.editSource.projectId
      const data = {
        accountId:  that.currentProject.accountId || this.editSource.accountId
      };
      that.listAllUsers(data).then((res) => {
        let userList = []
        let userAvailableList = []
        _.forEach(res , (user) =>{
          if(_.contains(user.confJson.aiProjects , '*')){
            userList.push(user)
          }else{
            if(_.contains(user.confJson.aiProjects , currentProjectId)){
              userList.push(user)
            }else{
              userAvailableList.push(user)
            }
          }
        })
        that.userAvailableList = userAvailableList
        that.userList = userList;
        that.tableLoading = false;
      });
    },
    addUserToProject(user){
      let that = this
      that.tableLoading = true;
      let currentAIAccount = _.find(that.aiAccounts, account => account.id == that.currentProject.accountId);
      let project_ids = _.pluck(currentAIAccount.projects , 'id')
      let allowed_project = user.confJson.aiProjects
      allowed_project.push(that.currentProject.id)
      if(project_ids.length === allowed_project.length){
        allowed_project = ['*']
      }
      user.confJson.aiProjects = allowed_project
        const update_user = {
          id: user.id,
          confJson: user.confJson,
        };
        that.updateAdminUser(update_user).then((res) => {
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Action Successful',
          text: 'Admin account updated successfully',
        });
      }).catch((err) => {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Action Error',
          text: err.errmsg,
        });
      }).finally(() =>{
        that.tableLoading = false;
        that.loadUsers()
        that.userToAdd = ''
      })
    },
    removeAccess(){
      let that = this
      that.tableLoading = true;
      let currentAIAccount = _.find(that.aiAccounts, account => account.id == that.currentProject.accountId);
      let project_ids = _.pluck(currentAIAccount.projects , 'id')
      _.forEach(that.selectedUsers , (user , index) =>{
        if(_.contains(user.confJson.aiProjects , '*')){
         user.confJson.aiProjects =  _.reject(project_ids, function(project){ return project === that.currentProject.id })
        }else{
         user.confJson.aiProjects =  _.reject(user.confJson.aiProjects, function(project){ return project === that.currentProject.id })
        }
        const update_user = {
          id: user.id,
          confJson: user.confJson,
        };
        that.updateAdminUser(update_user).then((res) => {
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Action Successful',
          text: 'Admin account updated successfully',
        });
        }).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        }).finally(() =>{
          if(index + 1 === that.selectedUsers.length){
            setTimeout(() => {
              that.tableLoading = false;
              that.loadUsers()
              that.userToAdd = ''
              that.selectedUsers = []
            }, 100);
          }
        })
      })
    },
    confirmDelete() {
      const that = this;
      if (that.delText == "DELETE") {
        that.deleteProject(that.currentProject).then(res => {
            this.$notify({
              group: "notifications",
              type: "success",
              title: "Action Successful",
              text: "AI Project deleted successfully"
            });
            that.getActiveAIAccounts({ accountId: that.selectedAIAccount.id });
            that.closeDrawer();
            if(this.$route.name !== 'aiProjects'){
              that.$router.push({name: 'aiProjects'}).catch((err) => {})
            }
          }).catch(err => {
            this.$notify({
              group: "notifications",
              type: "error",
              title: "Action Error",
              text: err.errmsg
            });
          });
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          title: "Invalid Data",
          text: "type DELETE and then hit the delete button"
        });
      }
    },
    openAIVendorDrawer() {
      const that = this;
      that.aiVendorActionType = "new";
      that.$store.commit("SET_AIVENDORS_DRAWER", true);
    },
    openChannelDrawer() {
      const that = this;
      that.channelActionType = "new";
      that.$store.commit("SET_CHANNEL_DRAWER", true);
    },
    editChannelDrawer(channel) {
      const that = this;
      that.channelActionType = "edit";
      that.currentChannelID = channel.channel_account_id;
      that.$store.commit("SET_CHANNEL_DRAWER", true);
    },
    editAIVendorDrawer(vendor) {
      const that = this;
      that.aiVendorActionType = "edit";
      that.currentAIVendorID = vendor.id;
      that.$store.commit("SET_AIVENDORS_DRAWER", true);
    },
    onFilePicked() {
      const that = this;
      const file = event.target.files[0];
      that.fileUploaded = true;
      that.fileSrc = URL.createObjectURL(event.target.files[0]);
      that.fileToUpload = file;
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === "object" && Object.keys(value).length === 0) || (typeof value === "string" && value.trim().length === 0)) {
        return false;
      }
      return true;
    }
  },
  watch: {
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
        that.currentAIAccount = _.find(newVal, account => account.id == that.selectedAIAccount.id);
      }
    },
    currentAIAccount(newVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.$store.commit("SET_ADMIN_AI_ACCOUNT", newVal);
        }
      }
    },
    aiVendorsDrawer(newVal) {
      if (newVal == true) {
        this.outerWidth = 100;
      } else {
        this.outerWidth = 85;
        this.loadCurrentProject(this.currentProject.id , 'aiVendorsDrawer');
      }
    },
    channelDrawer(newVal) {
      if (newVal == true) {
        this.outerWidth = 100;
      } else {
        this.outerWidth = 85;
        this.loadCurrentProject(this.currentProject.id , 'channelDrawer');
      }
    },
    showDrawer(newVal) {
      const that = this;
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.7)";
        that.currentAIAccount = _.find(
          that.aiAccounts,
          account => account.id == that.selectedAIAccount.id
        );
        that.$store.commit("SET_AIVENDORS_DRAWER", false);
      } else {
        that.boxShadow = "";
        that.getActiveAIAccounts({ accountId: that.currentProject.accountId });
      }
    },
    currentProject: {
      deep: true,
      handler(newVal) {
        const that = this;
        if (that.isValid(newVal.confJson.environment)) {
          that.enviromentLabel = "";
           that.industryLabel = "";
          if (typeof newVal.confJson.environment === "object") {
            that.currentProject.confJson.environment = newVal.confJson.environment.value;
          }
          const dateRange = _.find(that.shortcutList, shortcut => shortcut.key == newVal.confJson.dateRange);
          if (dateRange !== undefined) {
            that.dateRange = {
              label: dateRange.label,
              value: dateRange.key
            }
          }
          const timezone = _.find(that.timeZoneList, tz => tz.value == newVal.confJson.timeZone);
          if(timezone !== undefined){
            that.timezone = timezone;
          }
        } else {
          that.enviromentLabel = "is your bot live or in testing phase?";
           that.industryLabel = "what industry is your chatbot serving?";
        }
      }
    },
    userToAdd(newVal){
      if(_.isObject(newVal)){
        this.addUserToProject(newVal)
        this.userToAdd = 'Adding user....'
      }
    }
  },
  mounted() {
    this.showDrawer = false;
    this.currentProject = {
      name: "",
      confJson: {
        channels: [],
        industry: "",
        defaultProject: true
      },
      aiVendorlist: [],
      channelList: []
    };
    this.delText = ""
    this.fileUploaded = false
    this.fileSrc = ""
    this.fileToUpload = null;
  },
  created() {
    this.currentProject = {
      name: "",
      confJson: {
        channels: [],
        industry: "",
        defaultProject: true
      },
      aiVendorlist: [],
      channelList: []
    };
    eventBus.$on("triggerProjectDrawer", this.toggleDrawer);
  },
  beforeDestroy() {
    eventBus.$off("triggerProjectDrawer", this.toggleDrawer);
  },
  destroyed() {
    eventBus.$off("triggerProjectDrawer", this.toggleDrawer);
  }
};
