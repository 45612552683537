import API from '@/plugins/API';
import moment from 'moment';
import * as _ from 'underscore';

const momentTz = require('moment-timezone');

export const requestAISessionsList = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiSession&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&summary=true`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      const orderedArr = [];
      const { orderedKeys } = res.data;
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        delete item.custom_infoJso;
        item.index = index;
        item.timeStamp = momentTz.tz(item.start_time, timeZone).format('HH');
        item.weekDay = momentTz.tz(item.start_time, timeZone).format('dddd');
        item.startTime = momentTz.tz(item.start_time, timeZone).format('DD MM YYYY HH:mm:ss');
        item.start_time = momentTz.tz(item.start_time, timeZone).format('DD MMM YYYY');
        item.duration = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'minutes');
        if (item.lastUserMessageDate) {
          item.end_time = momentTz.tz(item.lastUserMessageDate, timeZone).format('DD MM YYYY HH:mm:ss');
        } else {
          item.end_time = moment(item.startTime, 'DD MM YYYY HH:mm:ss').add(30, 'm').format('DD MM YYYY HH:mm:ss');
        }
        item.duration = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'minutes');
        item.durationSeconds = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'seconds');
        orderedArr.push(item);
      }));
      commit('SET_AI_SEESIONS_LIST', orderedArr);
      const unique_flags = []; const unique_output = []; const l = orderedArr.length; let
        i;
      for (i = 0; i < l; i++) {
        if (unique_flags[orderedArr[i].start_time]) continue;
        unique_flags[orderedArr[i].start_time] = true;
        unique_output.push(orderedArr[i].start_time);
      }
      const result = [];
      unique_output.forEach((flag) => {
        const sessionsPerDay = orderedArr.filter((session) => session.start_time == flag);
        const senderRequestsPerDayCount = [];
        const sessionsPerDayCount = [];
        _.forEach(sessionsPerDay, (session) => {
          senderRequestsPerDayCount.push(session.senderRequests); // adding of all the sender requests
          sessionsPerDayCount.push(1);// add no of single
        });
        const senderRequestsPerDaysum = _.reduce(senderRequestsPerDayCount, (memo, num) => memo + num, 0);
        const sessionsPerDayysum = _.reduce(sessionsPerDayCount, (memo, num) => memo + num, 0);

        result.push({
          start_time: flag,
          senderRequests: senderRequestsPerDaysum,
          sessions: sessionsPerDayysum,
        });
      });
      if (result.length > 0) {
        if (result.length > 1) {
          commit('SET_AI_SESSIONS_FILTERED', result);
          resolve({
            status: 2,
            result,
            orderedArr,
          });
        } else {
          commit('SET_AI_SESSIONS_FILTERED', result);
          resolve({
            status: 1,
            result,
            orderedArr,
          });
        }
      } else {
        reject({
          status: 0,
          orderedArr: [],
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestAISessionsPerDay = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiSession&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&summary=true`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }

      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      const orderedArr = [];
      const { orderedKeys } = res.data;
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        item.index = index,
        item.category = item.index;
        item.timeStamp = momentTz.tz(item.start_time, timeZone).format('HH');
        item.weekDay = momentTz.tz(item.start_time, timeZone).format('dddd');
        item.startTime = momentTz.tz(item.start_time, timeZone).format('DD MM YYYY HH:mm:ss');
        item.start_time = momentTz.tz(item.start_time, timeZone).format('DD MMM YYYY');
        item.duration = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'minutes');
        if (item.lastUserMessageDate) {
          item.end_time = momentTz.tz(item.lastUserMessageDate, timeZone).format('DD MM YYYY HH:mm:ss');
        } else {
          item.end_time = moment(item.startTime, 'DD MM YYYY HH:mm:ss').add(30, 'm').format('DD MM YYYY HH:mm:ss');
        }
        item.duration = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'minutes');
        item.durationSeconds = moment(item.end_time, 'DD MM YYYY HH:mm:ss').diff(moment(item.startTime, 'DD MM YYYY HH:mm:ss'), 'seconds');
        orderedArr.push(item);
      }));
      commit('SET_AI_SEESIONS_LIST', orderedArr);
      const unique_output = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
      const result = [];
      unique_output.forEach((flag) => {
        const sessionsPerDay = orderedArr.filter((session) => session.timeStamp == flag);
        const senderRequestsPerHourcount = [];
        const sessionPerhourCount = [];
        _.filter(sessionsPerDay, (session) => {
          senderRequestsPerHourcount.push(session.senderRequests);
          sessionPerhourCount.push(1);
        });
        const senderRequestsPerHoursum = _.reduce(senderRequestsPerHourcount, (memo, num) => memo + num, 0);
        const sessionsPerHoursum = _.reduce(sessionPerhourCount, (memo, num) => memo + num, 0);
        result.push({
          start_time: flag,
          senderRequests: senderRequestsPerHoursum,
          sessions: sessionsPerHoursum,
        });
      });
      if (orderedArr.length > 0) {
        resolve({
          status: 2,
          result: result.reverse(),
          orderedArr,
        });
      } else {
        reject({
          status: 0,
          orderedArr: [],
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  }
});

export const requestChannelLogs = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiSession&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&pageSize=${data.pageSize}&pageNr=${data.pageNr}`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      const orderedArr = [];
      const { orderedKeys } = res.data;
      const { totSize } = res.data;
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        item.class = '';
        item.index = index;
        if (item.messages.length) {
          item.userMessages = _.filter(item.messages, (message) => message.sourcetype == 'user');
          item.userMsg = item.userMessages.length;
          item.starred = _.some(item.messages, (message) => message.starred);
          item.flagged = _.some(item.messages, (message) => message.reviewStatus === 'flagged');
          item.reviewed = _.some(item.messages, (message) => message.reviewStatus === 'reviewed');
          const greenLight = _.countBy(item.userMessages, (message) => message.reviewStatus == undefined || message.reviewStatus == 'unflagged' || message.reviewStatus == 'reviewed').true || 0;
          const redLight = _.countBy(item.userMessages, (message) => message.reviewStatus == 'flagged').true || 0;
          item.trafficLightInfo = {
            total: item.messages.length,
            greenLight: {
              count: greenLight,
              percentage: (greenLight / item.userMsg) * 100,
            },
            redLight: {
              count: redLight,
              percentage: (redLight / item.userMsg) * 100,
            },
          };
          if (item.userMsg > 0) {
            item.showTrafficInfo = true;
          } else {
            item.showTrafficInfo = false;
          }
          const sessionKeys = Object.keys(item.messages[0]);
          const hasUsername = _.contains(sessionKeys, 'nick');
          if (hasUsername) {
            item.userName = item.messages[0].nick;
          }
          item.hasUsername = hasUsername;
        } else {
          item.starred = false;
          item.flagged = false;
          item.reviewed = false;
          item.hasUsername = false;
          item.showTrafficInfo = false;
        }
        item.startTime = momentTz.tz(item.start_time, timeZone)
          .format('MMM/DD/YYYY HH:mm:ss');
        if (data.firstCall == true) {
          if (index == 0) {
            item.class = 'activeChat';
          } else {
            item.class = '';
          }
        } else {
          item.class = '';
        }

        orderedArr.push(item);
      }));
      resolve({
        orderedArr,
        totSize,
      });
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestAITrendsSessions = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiSession&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&summary=true`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      const orderedArr = [];
      const { orderedKeys } = res.data;
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        // date formate with seconds for showin on label of charts
        item.startDTime = momentTz.tz(item.start_time, timeZone).format('DD MMM YYYY HH:mm:ss');
        item.Date = momentTz.tz(item.start_time, timeZone).format('DD MM YYYY');
        item.start = momentTz.tz(item.start_time, timeZone).format('YYYY-MM-DD-HH-mm-ss');
        item.endDTime = momentTz.tz(item.lastUserMessageDate, timeZone).format('DD MMM YYYY HH:mm:ss');
        item.end = momentTz.tz(item.lastUserMessageDate, timeZone).format('YYYY-MM-DD-HH-mm-ss');
        orderedArr.push(item);
      }));

      const unique_flags = []; const unique_output = []; const l = orderedArr.length; let
        i;
      for (i = 0; i < l; i++) {
        if (unique_flags[orderedArr[i].Date]) continue;
        unique_flags[orderedArr[i].Date] = true;
        unique_output.push(orderedArr[i].Date);
      }
      resolve({
        result: orderedArr,
        unique_output,
      });
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestAudienceBehaviour = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=audienceBehaviour&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { columnsIndex } = res.data;
        const hour_columns = [
          {
            time: '12 AM',
            minutes: '0',
          },
          {
            time: '1 AM',
            minutes: '60',
          },
          {
            time: '2 AM',
            minutes: '120',
          },
          {
            time: '3 AM',
            minutes: '180',
          },
          {
            time: '4 AM',
            minutes: '240',
          },
          {
            time: '5 AM',
            minutes: '300',
          },
          {
            time: '6 AM',
            minutes: '360',
          },
          {
            time: '7 AM',
            minutes: '420',
          },
          {
            time: '8 AM',
            minutes: '480',
          },
          {
            time: '9 AM',
            minutes: '540',
          },
          {
            time: '10 AM',
            minutes: '600',
          },
          {
            time: '11 AM',
            minutes: '660',
          },
          {
            time: '12 PM',
            minutes: '720',
          },
          {
            time: '01 PM',
            minutes: '780',
          },
          {
            time: '02 PM',
            minutes: '840',
          },
          {
            time: '03 PM',
            minutes: '900',
          },
          {
            time: '04 PM',
            minutes: '960',
          },
          {
            time: '05 PM',
            minutes: '1020',
          },
          {
            time: '06 PM',
            minutes: '1080',
          },
          {
            time: '07 PM',
            minutes: '1140',
          },
          {
            time: '08 PM',
            minutes: '1200',
          },
          {
            time: '09 PM',
            minutes: '1260',
          },
          {
            time: '10 PM',
            minutes: '1320',
          },
          {
            time: '11 PM',
            minutes: '1380',
          },
        ];
        const { rowsIndex } = res.data;

        const weekdays = _.map(rowsIndex, (item) => item[0]);
        const rowData = [];
        _.forEach(res.data.rows, (row, rindex) => { // loop over all the rows to get data
          _.forEach(hour_columns, (hour) => { // loop over local hour colum array to check if row have hour data
            if (_.contains(columnsIndex, hour.minutes)) { // if columnsIndex contain the minutes of hour
              const hourIndex = _.indexOf(columnsIndex, hour.minutes); // find the index of minutes
              rowData.push({
                hour: hour.time, // time from hour array
                weekday: weekdays[rindex], // week no from weekdays array
                value: row[hourIndex], // value from row at hourIndex
              });
            } else { // if hour is not here then just push 0 value here
              rowData.push({
                hour: hour.time, // time from hour array
                weekday: weekdays[rindex], // week no from weekdays array
                value: 0, // static 0 value
              });
            }
          });
        });
        const messagesCount = [];
        const hours_flag = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
        const unique_flags = []; const unique_output = []; const l = rowData.length; let
          i;
        for (i = 0; i < l; i++) {
          if (unique_flags[rowData[i].hour]) continue;
          unique_flags[rowData[i].hour] = true;
          unique_output.push(rowData[i].hour);
        }
        _.forEach(unique_output, (output, hIndex) => {
          const sessionsPerHour = rowData.filter((sessionCount) => sessionCount.hour == output);
          const sessionPerHourCount = [];
          _.filter(sessionsPerHour, (sessionCount) => {
            sessionPerHourCount.push(sessionCount.value);
          });
          const sum = _.reduce(sessionPerHourCount, (memo, num) => memo + num, 0);
          messagesCount.push({
            flag: hours_flag[hIndex],
            value: sum,
          });
        });
        resolve({
          result: rowData,
          messagesCount,
        });
      } else {
        resolve({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsFlow = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsFlow&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = [];
        _.each(rowsIndex, (headers, index) => {
          const from = headers[_.indexOf(topleftCell, 'FROM')];
          const to = headers[_.indexOf(topleftCell, 'TO')];
          return result.push({
            from,
            to,
            id: headers[_.indexOf(topleftCell, 'ID')],
            level: Number(headers[_.indexOf(topleftCell, 'ID')].split('-')[1]),
            value: rows[index][0],
          });
        });
        resolve({
          result,
        });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsFlowcompressed = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsFlowcompressed&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = _.map(rowsIndex, (headers, index) => {
          if (headers[_.indexOf(topleftCell, 'FROM')] !== headers[_.indexOf(topleftCell, 'TO')]) {
            return {
              from: headers[_.indexOf(topleftCell, 'FROM')],
              to: headers[_.indexOf(topleftCell, 'TO')],
              id: headers[_.indexOf(topleftCell, 'ID')],
              path: Number(headers[_.indexOf(topleftCell, 'ID')].split('_')[0]),
              level: Number(headers[_.indexOf(topleftCell, 'ID')].split('-')[1]),
              value: rows[index][0],
            };
          }
        });
        resolve({ result });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsFlowExtended = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsFlowextended&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = [];
        _.each(rowsIndex, (headers, index) => result.push({
          from: headers[_.indexOf(topleftCell, 'FROM')],
          to: headers[_.indexOf(topleftCell, 'TO')],
          id: headers[_.indexOf(topleftCell, 'ID')],
          path: Number(headers[_.indexOf(topleftCell, 'ID')].split('_')[0]),
          level: Number(headers[_.indexOf(topleftCell, 'ID')].split('-')[1]),
          value: rows[index][0],
        }));
        resolve({ result });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsFlowPlain = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsFlowplain&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = [];
        _.each(rowsIndex, (headers, index) => result.push({
          from: headers[_.indexOf(topleftCell, 'FROM')],
          to: headers[_.indexOf(topleftCell, 'TO')],
          id: headers[_.indexOf(topleftCell, 'ID')],
          level: Number(headers[_.indexOf(topleftCell, 'ID')].split('-')[1]),
          value: rows[index][0],
        }));
        resolve({ result });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsFlow2All = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsFlow2all&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const parametersArray = [];
        const parameter_data = []
        const result = _.map(rowsIndex, (headers, index) => {
          if (JSON.parse(headers[_.indexOf(topleftCell, 'parameters')]).length > 0) {
            parametersArray.push(JSON.parse(headers[_.indexOf(topleftCell, 'parameters')]));
          }
          _.forEach(JSON.parse(headers[_.indexOf(topleftCell, 'parameters')]) , parametersGroup => {
            // console.log(parametersGroup)
            parameter_data.push({
              'Intent': headers[_.indexOf(topleftCell, 'INTENT')],
              'Group': parametersGroup.group,
              'Parameter': parametersGroup.name ,
              'Value': parametersGroup.value,
            })
          })
          return {
            name: headers[_.indexOf(topleftCell, 'INTENT')],
            value: rows[index][0],
            linkWith: JSON.parse(headers[_.indexOf(topleftCell, 'linkWith')]),
            children: JSON.parse(headers[_.indexOf(topleftCell, 'parameters')]),
          };
        });
        const groupByParameters = _.groupBy(_.flatten(parametersArray), (obj) => obj.name);
        const mapped_data = [];
        for (const parameter in groupByParameters) {
          if (groupByParameters.hasOwnProperty(parameter)) {
            const mapped_obj = {
              name: groupByParameters[parameter][0].name,
              group: groupByParameters[parameter][0].group,
              value: groupByParameters[parameter][0].value,
            };
            mapped_data.push(mapped_obj);
          }
        }
        resolve({ result, mappedData: mapped_data , parameter_data});
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsTopic = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsTopic&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = _.map(rowsIndex, (headers, index) => ({
          topic: headers[_.indexOf(topleftCell, 'TOPIC')],
          intent: headers[_.indexOf(topleftCell, 'INTENT')],
          total: rows[index][0],
        }));
        const groupByResult = _.groupBy(result, (obj) => obj.topic);
        const values = [];
        const mapped_data = {

        };
        for (var property in groupByResult) {
          if (groupByResult.hasOwnProperty(property)) {
            mapped_data[property] = [];
            _.forEach(groupByResult[property], (intentObj) => {
              mapped_data[property].push([intentObj.intent, intentObj.total]);
              values.push(intentObj.total);
            });
          }
        }
        resolve({
          result: mapped_data,
          min: _.min(values),
          max: _.max(values) + 5,
        });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestConversationsTopicParameters = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversationsTopicParameters&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        const { topleftCell } = res.data;
        const result = _.map(rowsIndex, (headers, index) => {
          const chart_point = {
            topic: headers[_.indexOf(topleftCell, 'TOPIC')],
            intent: headers[_.indexOf(topleftCell, 'INTENT')],
            value: rows[index][0],
          };
          if (headers[_.indexOf(topleftCell, 'parameter')] !== '') {
            chart_point.parameter = headers[_.indexOf(topleftCell, 'parameter')];
          }
          return chart_point;
        });
        const mapped_data = [];
        const topicData = [];
        const parameter_data = []
        const groupByTopicResult = _.groupBy(result, (obj) => obj.topic);
        for (const property in groupByTopicResult) {
          if (groupByTopicResult.hasOwnProperty(property)) {
            topicData.push({
              name: groupByTopicResult[property][0].topic,
              value: _.reduce(_.pluck(groupByTopicResult[property], 'value'), (memo, num) => memo + num, 0),
            });
            const mapped_obj = {
              name: groupByTopicResult[property][0].topic,
              children: [],
            };
            const groupByResult = _.groupBy(result, (obj) => obj.intent);
            for (const subProperty in groupByResult) {
              if (groupByTopicResult[property][0].topic === groupByResult[subProperty][0].topic) {
                if (groupByResult.hasOwnProperty(subProperty)) {
                  if (groupByResult[subProperty].length > 1) {
                    const obj_withChild = {
                      name: `${groupByResult[subProperty][0].intent}`,
                      children: [],
                    };
                    _.forEach(groupByResult[subProperty], (childObj) => {
                      parameter_data.push({
                        'Topic': groupByTopicResult[property][0].topic,
                        'Intent': groupByResult[subProperty][0].intent,
                        'Parameter': childObj.parameter ,
                        'Value': childObj.value,
                      })
                      obj_withChild.children.push({ name: childObj.parameter || 'None', value: childObj.value });
                    });
                    mapped_obj.children.push(obj_withChild);
                  } else if (groupByResult[subProperty][0].hasOwnProperty('parameter')) {
                    mapped_obj.children.push({
                      name: groupByResult[subProperty][0].intent,
                      children: [{
                        name: groupByResult[subProperty][0].parameter,
                        value: groupByResult[subProperty][0].value,
                      }],
                    });
                    parameter_data.push({
                      'Topic': groupByTopicResult[property][0].topic,
                      'Intent': groupByResult[subProperty][0].intent,
                      'Parameter': groupByResult[subProperty][0].parameter,
                      'Value': groupByResult[subProperty][0].value,
                    })
                  } else {
                    mapped_obj.children.push({
                      name: groupByResult[subProperty][0].intent,
                      value: groupByResult[subProperty][0].value,
                    });
                  }
                }
              }
            }
            mapped_data.push(mapped_obj);
          }
        }
        resolve({ result: mapped_data, topicData ,  parameter_data });
      } else {
        reject({
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestIntentStats = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=intentStats&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=${data.slotMins}`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const headers = [];
        const { columnsIndex } = res.data;
        const { topleftCell } = res.data;
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        headers[0] = {
          label: 'intent',
          value: topleftCell[_.indexOf(topleftCell, 'INTENT')],
        };
        _.forEach(columnsIndex, (value, index) => {
          if (value !== 'Total') {
            headers.push({
              label: value,
              index,
              value: momentTz.tz(value, 'x', timeZone).format('DD MMM YYYY'),
            });
          } else {
            headers.push({
              label: value,
              index,
              value,
            });
          }
        });
        const result = [];
        _.forEach(rowsIndex, (rowData, index) => {
          const obj = {
            index: index + 1,
            intent: rowData[_.indexOf(topleftCell, 'INTENT')],
            topic: rowData[_.indexOf(topleftCell, 'TOPIC')],
            valueList: [],
          };
          const targetRow = rows[index];
          _.forEach(headers, (head) => {
            if (head.index >= 0) {
              const allKeys = _.map(_.keys(targetRow), (num) => Number(num));
              if (_.contains(allKeys, head.index)) {
                const percentage = ((Number(targetRow[head.index].slice(1).split('/')[0]) / Number(targetRow[head.index].slice(1).split('/')[1])) * 100).toFixed(2);
                obj[head.label] = {
                  label: targetRow[head.index].slice(1),
                  confidanceScore: Number(targetRow[head.index].slice(1).split('/')[0]),
                  occurance: Number(targetRow[head.index].slice(1).split('/')[1]),
                  confidencePercentage: percentage,
                };
                obj.valueList.push({
                  confidanceScore: Number(targetRow[head.index].slice(1).split('/')[0]),
                  occurance: Number(targetRow[head.index].slice(1).split('/')[1]),
                  confidencePercentage: percentage,
                });
              } else {
                obj[head.label] = {
                  label: 0,
                  confidanceScore: 0,
                  occurance: 0,
                  confidencePercentage: 0,
                };
              }
            }
          });
          result.push(obj);
        });
        if (result.length > 0) {
          resolve({
            headers,
            result: _.sortBy(result, 'intent'),
          });
        } else {
          reject({
            headers,
            result,
          });
        }
      } else {
        reject({
          err: 'Empty data',
          headers: [],
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

export const requestIntentStatsAll = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
    const { timeZone } = rootState.auth.currentUser.confJson;
    API().get(`/evoai/admin/project/${data.aiAccount}/report?item=intentStatsAll&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=${data.slotMins}`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if (res.data.errcode == 0) {
        const headers = [];
        const { columnsIndex } = res.data;
        const { topleftCell } = res.data;
        const { rowsIndex } = res.data;
        const { rows } = res.data;
        headers[0] = {
          label: 'intent',
          value: topleftCell[_.indexOf(topleftCell, 'INTENT')],
        };
        _.forEach(columnsIndex, (value, index) => {
          if (value !== 'Total') {
            headers.push({
              label: value,
              index,
              value: momentTz.tz(value, 'x', timeZone).format('DD MMM YYYY'),
            });
          } else {
            headers.push({
              label: value,
              index,
              value,
            });
          }
        });
        const result = [];
        _.forEach(rowsIndex, (rowData, index) => {
          const obj = {
            index: index + 1,
            vendor: rowData[_.indexOf(topleftCell, 'VENDOR')],
            intent: rowData[_.indexOf(topleftCell, 'INTENT')].toLowerCase(),
            topic: rowData[_.indexOf(topleftCell, 'TOPIC')],
            valueList: [],
          };
          const targetRow = rows[index];
          _.forEach(headers, (head) => {
            if (head.index >= 0) {
              const allKeys = _.map(_.keys(targetRow), (num) => Number(num));
              if (_.contains(allKeys, head.index)) {
                let percentage = 0;
                if (Number(targetRow[head.index].slice(1).split('/')[1])) {
                  percentage = ((Number(targetRow[head.index].slice(1).split('/')[0]) / Number(targetRow[head.index].slice(1).split('/')[1])) * 100).toFixed(2);
                }
                obj[head.label] = {
                  label: targetRow[head.index].slice(1),
                  confidanceScore: Number(targetRow[head.index].slice(1).split('/')[0]),
                  occurance: Number(targetRow[head.index].slice(1).split('/')[1]) || 0,
                  confidencePercentage: percentage,
                };
                obj.valueList.push({
                  confidanceScore: Number(targetRow[head.index].slice(1).split('/')[0]),
                  occurance: Number(targetRow[head.index].slice(1).split('/')[1]) || 0,
                  confidencePercentage: percentage,
                });
              } else {
                obj[head.label] = {
                  label: 0,
                  confidanceScore: 0,
                  occurance: 0,
                  confidencePercentage: 0,
                };
              }
            }
          });
          result.push(obj);
        });
        resolve({
          headers,
          result,
        });
      } else {
        reject({
          err: 'Empty data',
          headers: [],
          result: [],
        });
      }
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});

// dashboard overview API

export const requestSessionsOverview = ({ rootState, commit, dispatch }, data) =>
  new Promise((resolve, reject) => {
    if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=sessionsOverview&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}&slotMins=60`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        if (res.data.errcode == 0) {
          const hour_columns = [
            {
              time: 'Total',
              minutes: 'Total',
              hourNumber: 'total',
            },
            {
              time: '12 AM',
              minutes: '0',
              hourNumber: 0,
            },
            {
              time: '1 AM',
              minutes: '60',
              hourNumber: 1,
            },
            {
              time: '2 AM',
              minutes: '120',
              hourNumber: 2,
            },
            {
              time: '3 AM',
              minutes: '180',
              hourNumber: 3,
            },
            {
              time: '4 AM',
              minutes: '240',
              hourNumber: 4,
            },
            {
              time: '5 AM',
              minutes: '300',
              hourNumber: 5,
            },
            {
              time: '6 AM',
              minutes: '360',
              hourNumber: 6,
            },
            {
              time: '7 AM',
              minutes: '420',
              hourNumber: 7,
            },
            {
              time: '8 AM',
              minutes: '480',
              hourNumber: 8,
            },
            {
              time: '9 AM',
              minutes: '540',
              hourNumber: 9,
            },
            {
              time: '10 AM',
              minutes: '600',
              hourNumber: 10,
            },
            {
              time: '11 AM',
              minutes: '660',
              hourNumber: 11,
            },
            {
              time: '12 PM',
              minutes: '720',
              hourNumber: 12,
            },
            {
              time: '01 PM',
              minutes: '780',
              hourNumber: 13,
            },
            {
              time: '02 PM',
              minutes: '840',
              hourNumber: 14,
            },
            {
              time: '03 PM',
              minutes: '900',
              hourNumber: 15,
            },
            {
              time: '04 PM',
              minutes: '960',
              hourNumber: 16,
            },
            {
              time: '05 PM',
              minutes: '1020',
              hourNumber: 17,
            },
            {
              time: '06 PM',
              minutes: '1080',
              hourNumber: 18,
            },
            {
              time: '07 PM',
              minutes: '1140',
              hourNumber: 19,
            },
            {
              time: '08 PM',
              minutes: '1200',
              hourNumber: 20,
            },
            {
              time: '09 PM',
              minutes: '1260',
              hourNumber: 21,
            },
            {
              time: '10 PM',
              minutes: '1320',
              hourNumber: 22,
            },
            {
              time: '11 PM',
              minutes: '1380',
              hourNumber: 23,
            },
          ];
          const { columnsIndex } = res.data;
          const { rowsIndex } = res.data;
          const { rows } = res.data;
          const rowData = [];
          const final_data = [];
          const time_data = [];
          const hour_data = [];
          const hour_messages = [];
          _.forEach(rowsIndex, (rIndex) => {
            rowData.push({
              rowTitle: rIndex[0],
            });
          });
          _.forEach(rows, (row, rowIndex) => {
            const returnObj = {
              rowTitle: rowData[rowIndex].rowTitle,
            };
            const returnTime = {
              rowTitle: rowData[rowIndex].rowTitle,
            };
            const returnHour = {
              rowTitle: rowData[rowIndex].rowTitle,
            };
            const returnHourMessage = {
              rowTitle: rowData[rowIndex].rowTitle,
            };
            _.forEach(hour_columns, (hour) => {
              if (_.contains(columnsIndex, hour.minutes)) { // if columnsIndex contain the minutes of hour
                const hourIndex = _.indexOf(columnsIndex, hour.minutes); // find the index of minutes
                returnObj[hour.minutes] = row[hourIndex];
                returnTime[hour.time] = row[hourIndex];
                if (returnHour.rowTitle == 'TOTSESSIONS') {
                  if (hour.hourNumber !== 'total') {
                    returnHour[hour.hourNumber] = row[hourIndex];
                  }
                }
                if (returnHourMessage.rowTitle == 'TOTMESSAGES') {
                  if (hour.hourNumber !== 'total') {
                    returnHourMessage[hour.hourNumber] = row[hourIndex]
                  }
                }
              } else { // if hour is not here then just push 0 value here
                returnObj[hour.minutes] = 0;
                returnTime[hour.time] = 0;
                returnHourMessage[hour.hourNumber] = 0
              }
            });
            final_data.push(returnObj);
            time_data.push(returnTime);
            if (returnHour.rowTitle == 'TOTSESSIONS') {
              hour_data.push(returnHour);
            }
            if (returnHourMessage.rowTitle == 'TOTMESSAGES') {
              hour_messages.push(returnHourMessage);
            }
          });
          resolve({
            final_data,
            time_data,
            hour_data,
            hour_messages
          });
        } else {
          reject(err);
        }
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });


  export const requestAIMessages = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiMessage&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        if (res.data.errcode == 0) {
          const { topleftCell } = res.data;
          const { rowsIndex } = res.data;
          let result = _.map(rowsIndex , rowData =>{
            let created_time = momentTz.tz(rowData[_.indexOf(topleftCell, 'created_time')], 'x', timeZone).format('MMM/DD/YYYY HH:mm:ss');
            return {
              session_id: rowData[_.indexOf(topleftCell, 'session_id')],
              message_id:  rowData[_.indexOf(topleftCell, 'message_id')],
              mainParameter:  rowData[_.indexOf(topleftCell, 'mainParameter')],
              user_content:  rowData[_.indexOf(topleftCell, 'user_content')],
              response_content:  rowData[_.indexOf(topleftCell, 'response_content')],
              rating:  Number(Number(rowData[_.indexOf(topleftCell, 'rating')]).toFixed(2)),
              reviewStatus:  rowData[_.indexOf(topleftCell, 'reviewStatus')],
              response_reviewStatus:  rowData[_.indexOf(topleftCell, 'response_reviewStatus')],
              response_starred:  rowData[_.indexOf(topleftCell, 'response_starred')],
              created_time: created_time
            }
          })
          resolve({result})
        }else if(res.data.errcode == -1){
          resolve({result: []})
        }else{
          reject(res.data)
        }
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });
  

  export const requestAiMessageLog = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=aiMessageLog&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        const { topleftCell } = res.data;
        const { rowsIndex } = res.data;
        let filtered_data = _.map(res.data.rowsIndex , (rowData) =>{
          let created_time = momentTz.tz(rowData[_.indexOf(topleftCell, 'created_time')], 'x', timeZone).format('MMM/DD/YYYY HH:mm:ss')
          rowData[_.indexOf(topleftCell, 'created_time')] = created_time
          return rowData
        })
        resolve(({
          filtered_data,
          headers: topleftCell
        }))
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });  

  export const requestUserEngagement = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (data && data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=userEngagement&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        let filtered_data = []

        _.forEach(res.data.columnsIndex , (coloumn , columnsIndex) => {
          let mapped_column = {
            date: coloumn
          }
          _.forEach(res.data.rows , (row, rowsIndex) => {
            mapped_column[res.data.rowsIndex[rowsIndex][0]] =   row[columnsIndex]
          })
          filtered_data.push(mapped_column)
        })
        resolve(filtered_data)
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });


  export const requestUserRetentions = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=${data.reportItem}&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        let filtered_data = {}

        // console.log("requestUserRetentions " , res.data) 
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });


  export const requestConversionsFunnel = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
    if (data.aiAccount !== undefined && data.startdate !== undefined && data.startdate !== undefined) {
      const { timeZone } = rootState.auth.currentUser.confJson;
      API().get(`/evoai/admin/project/${data.aiAccount}/report?item=conversionsFunnel&intentsSeq=${data.intentsSeq}&startdate=${data.startdate}&enddate=${data.enddate}&timeZone=${timeZone}`).then((res) => {
        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        }
        const columnsIndex = res.data.columnsIndex
        if(columnsIndex.length > 0){
          const targetRow = res.data.rows[0]
          let intents_data = []
          _.forEach(columnsIndex , (intent , intentIndex) => {
            intents_data.push({
              intent,
              occurance: targetRow[intentIndex] || 0
            })
          })
          resolve(intents_data)
        }else{
          resolve([])
        }
      }).catch((err) => {
        reject(err);
      });
    } else {
      reject();
    }
  });
