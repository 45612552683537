import Vue from 'vue';
import Vuex from 'vuex';
import SecureLS from 'secure-ls';

// import modules
import auth from './modules/auth/store';
import general from './modules/general/store';
import config from './modules/config/store';
import report from './modules/report/store';
import user from './modules/user/store';
import project from './modules/project/store';
import file from './modules/file/store';

// import plugins
import createPersistedState from 'vuex-persistedstate';
import { plugin } from './plugins';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  plugins: [plugin, createPersistedState({
    key: 'evo_presence_storage',
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  modules: {
    auth,
    general,
    config,
    report,
    user,
    project,
    file,
  },
});

export default store;
