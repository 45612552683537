//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from '@/plugins/eventBus'
import { mapGetters, mapActions } from 'vuex'
import timeZone from '@/data/timezone.json'
import createUserModal from './createUserModal'
import projectDrawer from '../../project/drawer';
import userDrawer from './userDrawer'
import moment from 'moment'
import * as _ from 'underscore'

export default {
  name: 'CreateAccount',
  components: {
    createUserModal,
    userDrawer,
    projectDrawer
  },
  props: {
    actionType: {
      required: true,
      default: 'new',
    },
    AccountToEdit: {
      required: true,
    },
  },
  data() {
    return {
      componentName: 'CreateAccount',
      userList: [],
      currentAIAccount: '',
      currentAIAccountId: '',
      showProjectDrawer: false,
      isUserModal: false,
      filter: '',
      loading: false,
      actionTypeUser: 'new',
      selectedUsers: [],
      confirm: false, // confirm user delete
      multidelete: false, // delete multi user
      targetUser: {},
      userToEdit: {},
      showInnerLoading: false,
      timezone: {
        label: '[GMT+08:00] Perth, Shanghai, Singapore',
        value: 'Asia/Shanghai',
      },
      timeZoneList: timeZone,
      language: {
        label: 'English',
        value: 'en_US',
      },
      languageList: [
        {
          label: 'English',
          value: 'en_US',
        },
        {
          label: 'Nederlands',
          value: 'nl_NL',
        },
        {
          label: '中文',
          value: 'zh_CN',
        },
      ],
      dateRange: {
        label: 'This Month',
        value: 'thisMonth',
      },
      shortcutList: [
        {
          key: 'thisDay',
          label: this.$t('filterBar.calendarShortcuts[0]'),
          value: 'day',
        },
        {
          key: 'lastDay',
          label: this.$t('filterBar.calendarShortcuts[1]'),
          value: '-day',
        },
        {
          key: 'thisWeek',
          label: this.$t('filterBar.calendarShortcuts[2]'),
          value: 'isoWeek',
        },
        {
          key: 'lastWeek',
          label: this.$t('filterBar.calendarShortcuts[3]'),
          value: '-isoWeek',
        },
        {
          key: 'thisMonth',
          label: this.$t('filterBar.calendarShortcuts[4]'),
          value: () => ({
            start: moment().startOf('month'),
            end: moment(),
          }),
        },
        {
          key: 'last3Months',
          label: this.$t('filterBar.calendarShortcuts[5]'),
          value: () => ({
            start: moment().subtract(3, 'months').startOf('month'),
            end: moment().subtract(1, 'months').endOf('month'),
          }),
        },
        {
          key: 'lastYear',
          label: this.$t('filterBar.calendarShortcuts[6]'),
          value: '-year',
        },
      ],
      aiAccount: {
        name: '',
        confJson: {
          owner: {
            fname: '',
            lname: '',
            email: '',
          },
          expiryDate: '2021/12/31',
          serverLocation: 'China',
          accountType: 'Free',
          status: true,
          historicalDataLimit: 365,
          MAULimit: 10000,
          projectLimit: 10,
          reports: [],
          additionAPI: 2,
          export: true,
          annotations: true,
          calculations: true,
          companyBinding: true,
          agencyAdminView: true,
          autoActivate: true,
          systemSetting: {
            dateRange: 'thisMonth',
            language: 'en_US',
            timeZone: 'Asia/Shanghai',
          },
          confirmAdminUserEmail: true,
          adminWebsiteUrl: '',
        },
        projects: [],
      },
      serverLocationsList: ['China', 'UK', 'USA'],
      accountTypeList: ['Free', 'Pro', 'Custom'],
      password: '',
      active: true,
      passwordType: 'text',
      disableTXT: '',
      activateTXT: '',
      purgeTXT: '',
      columns: [
        {
          name: 'name',
          required: true,
          align: 'left',
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        {
          name: 'id',
          align: 'center',
          required: true,
          align: 'left',
          label: 'PROJECT ID',
          field: 'id',
          sortable: true,
        },
        {
          name: 'environment',
          label: 'ENVIRONMENT',
          required: true,
          align: 'left',
          field: 'environment',
        },
        {
          name: 'CREATION Date',
          label: 'DATE',
          required: true,
          align: 'left',
          field: 'date',
        },
      ],
      columnsUsers: [
        {
          name: 'environment',
          label: 'Email',
          required: true,
          align: 'left',
          field: 'email',
        },
        {
          name: 'name',
          required: true,
          align: 'left',
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        {
          name: 'Date',
          label: 'ROLE',
          required: true,
          align: 'left',
          field: 'role',
        },
      ],
      loginUrlList: [
        'https://console.evodynamics.io/',
        'https://console-asia.evodynamics.io/',
        'https://console-eu.evodynamics.io/',
        'https://develop.evodynamics.io/',
      ],
      pagination: {
        page: 1,
        rowsPerPage: 0, // 0 means all rows
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme',
      userDrawer: 'getUserDrawer',
    }),
  },
  methods: {
    ...mapActions({
      fetchAIAccount: 'fetchAIAccount',
      createNewAIAccount: 'createNewAIAccount',
      updateAIAccount: 'updateAIAccount',
      disableAIAccount: 'disableAIAccount',
      createNewAdminUser: 'createNewAdminUser',
      deleteAIACCOUNTCOMPLETELY: 'deleteAIACCOUNTCOMPLETELY',
      listAllUsers: 'listAllUsers',
      deleteUser: 'deleteUser',
      updateActiveAIAccount: 'updateActiveAIAccount',
    }),
    openCreateProjectDrawer(account) {
      const that = this
      that.isUserModal = false;
      that.showProjectDrawer = true;
      account.accountId = account.accountId
      eventBus.updateAIAccount(account)
      that.updateActiveAIAccount(account)
      
     eventBus.triggerProjectDrawer({ status: false, actionType: 'new' })
      
    },
    hideProjectDrawer(){debugger
      this.showProjectDrawer = false;
      },
    manageMyAccount() {
      window.open(' https://www.evodynamics.io/account/?utm_source=console');
    },
    addNewUser(val) {
      this.isUserModal = false;
      this.isUserModal = val
    },
    toggleEdit(user) {
      const that = this
      that.userToEdit = user
      that.currentAIAccountId = user.accountId;
      that.actionTypeUser = 'edit'
      setTimeout(() => {
        this.$store.commit('SET_USER_DRAWER', true)
      }, 200)
    },
    delUser() {
      const that = this
      const { targetUser } = that
      that
        .deleteUser(targetUser)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action Successful',
            text: 'Admin account deleted successfully',
          })
          that.targetUser = {}
          that.loadUsers()
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
    },
    deleteMultiple() {
      const that = this
      const { selectedUsers } = that
      selectedUsers.forEach((user) => {
        that.deleteUser(user).then((res) => {
           that.loadUsers()
        }).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
      })
      this.$notify({
        group: 'notifications',
        type: 'success',
        title: 'Action Successful',
        text: "All selected account's deleted successfully",
      })
     
      that.selectedUsers = []
    },
    toggleDrawer() {
      const that = this
      this.$store.commit('SET_USER_DRAWER', false)
      that.loadUsers()
    },
    loadAccount() {
      const that = this
      that.showInnerLoading = true
      that
        .fetchAIAccount(that.AccountToEdit.id)
        .then((resp) => {
          const account = resp.data
          account.projects.forEach((project) => {
            project.date = moment(project.created_time).format('DD MMM YYYY')
            project.environment = project.confJson.environment
          })
          that.aiAccount = account
          this.loadUsers()
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
        .finally(() => {
          that.showInnerLoading = false
           console.log("create account",this.aiAccount.projects);
        })
       
    },
    loadUsers() {
      const that = this
      if (
        this.currentUser.role == 'Admin' ||
        this.currentUser.role == 'Superuser'
      ) {
        that.loading = true
        const data = {
          accountId: this.aiAccount.id,
        }
        that.listAllUsers(data).then((res) => {
          that.userList = res
          that.loading = false
        })
      }
    },
    createAccount() {
      const that = this
      const { aiAccount } = that
      if (!that.inValid(aiAccount.name)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Name',
          text: 'AI Account Name is required',
        })
      } else if (!that.inValid(aiAccount.confJson.owner.fname)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Owner First name required',
        })
      } else if (!that.inValid(aiAccount.confJson.owner.lname)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Owner last name required',
        })
      } else if (!that.inValid(aiAccount.confJson.owner.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Owner Email required',
        })
      } else if (!that.inValid(that.password)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Owner Password Required',
        })
      } else if (!that.validateEmail(aiAccount.confJson.owner.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.invalidEmail'),
        })
      } else if (!that.inValid(aiAccount.confJson.adminWebsiteUrl)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Login url required*',
        })
      } else {
        that.showInnerLoading = true
        that
          .createNewAIAccount(aiAccount)
          .then((res) => {
            that.createNewUser(res.resourceId)
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
      }
    },
    updateAccout() {
      const that = this
      const { aiAccount } = that
      if (!that.inValid(aiAccount.name)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Name',
          text: 'AI Account Name is required',
        })
      } else if (!that.inValid(aiAccount.confJson.adminWebsiteUrl)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: 'Login url required*',
        })
      } else {
        that.showInnerLoading = true
        that
          .updateAIAccount(aiAccount)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action Successful',
              text: 'AI New Account updated successfully',
            })
            that.showInnerLoading = false
            this.$store.commit('SET_AI_ACCOUNT_DRAWER', false)
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
      }
    },

    confirmDisable() {
      const that = this
      if (that.disableTXT == 'DISABLE') {
        that
          .disableAIAccount(that.aiAccount)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action Successful',
              text: 'AI account disabled successfully',
            })
            this.$store.commit('SET_AI_ACCOUNT_DRAWER', false)
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type DISABLE and then hit the disable button',
        })
      }
    },

    activateAccount() {
      const that = this
      if (that.activateTXT == 'ACTIVATE') {
        that.aiAccount.status = ''
        that.updateAccout()
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type ACTIVATE and then hit the reactivate button',
        })
      }
    },
    purgeAIAccount() {
      const that = this
      if (that.purgeTXT == 'PURGE') {
        that
          .deleteAIACCOUNTCOMPLETELY(that.aiAccount)
          .then((res) => {
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action Successful',
              text: 'AI account deleted successfully',
            })
            this.$store.commit('SET_AI_ACCOUNT_DRAWER', false)
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type PURGE and then hit the delete button',
        })
      }
    },
    createNewUser(accountId) {
      const that = this
      const { aiAccount } = that
      const new_user = {
        accountId,
        name: `${aiAccount.confJson.owner.fname} ${aiAccount.confJson.owner.lname}`,
        email: aiAccount.confJson.owner.email,
        password: that.password,
        confJson: {
          roles: ['admin'],
          aiProjects: ['*'],
        },
      }
      that
        .createNewAdminUser(new_user)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action Successful',
            text: 'AI New Account created successfully',
          })
          that.showInnerLoading = false
          this.$store.commit('SET_AI_ACCOUNT_DRAWER', false)
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
    },
    generatePassword() {
      const that = this
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
      }
      that.passwordType = 'text'
      that.password = result
    },

    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false
      }
      return true
    },
  },

  watch: {
    aiAccounts(newVal) {
      const that = this
      if (newVal.length) {
        if (
          this.currentUser.role == 'Admin' ||
          this.currentUser.role == 'Superuser'
        ) {
          that.currentAIAccount = newVal[0]
          that.currentAIAccountId = that.currentAIAccount.id
        }
      }
    },
    currentAIAccount(newVal) {
      const that = this
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name
          that.currentAIAccountId = newVal.id
        }
        that.loadUsers()
      }
    },
    userDrawer(newVal) {
      const that = this
      this.loadUsers()
    },
    AccountToEdit(newVal) {
      const that = this
      that.aiAccount = newVal
      that.loadAccount()
      that.loadUsers()
      ;(that.password = ''),
        (that.active = true),
        (that.disableTXT = ''),
        (that.activateTXT = ''),
        (that.purgeTXT = '')
    },
    aiAccount: {
      deep: true,
      handler(newVal) {
        const that = this
        if (typeof newVal.status === 'object') {
          const dateRange = _.find(
            that.shortcutList,
            (shortcut) =>
              shortcut.key == newVal.confJson.systemSetting.dateRange,
          )
          if (dateRange !== undefined) {
            that.dateRange = {
              label: dateRange.label,
              value: dateRange.key,
            }
          }
          that.language = _.find(
            that.languageList,
            (lang) => lang.value == newVal.confJson.systemSetting.language,
          )
          that.timezone = _.find(
            that.timeZoneList,
            (tz) => tz.value == newVal.confJson.systemSetting.timeZone,
          )
        }
      },
    },
    actionType(newVal) {
      const that = this
      if (newVal == 'new') {
        this.aiAccount = {
          name: '',
          confJson: {
            owner: {
              fname: '',
              lname: '',
              email: '',
              password: '',
            },
            expiryDate: '2021/12/31',
            serverLocation: 'China',
            accountType: 'Free',
            status: true,
            historicalDataLimit: 365,
            MAULimit: 10000,
            projectLimit: 10,
            reports: [],
            additionAPI: 2,
            export: true,
            annotations: true,
            calculations: true,
            companyBinding: true,
            agencyAdminView: true,
            autoActivate: true,
            systemSetting: {
              dateRange: 'thisMonth',
              language: 'en_US',
              timeZone: 'Asia/Shanghai',
            },
            confirmAdminUserEmail: true,
            adminWebsiteUrl: '',
          },
          projects: [],
        }
      } else {
        this.loadAccount()
      }
      ;(that.password = ''),
        (that.active = true),
        (that.disableTXT = ''),
        (that.activateTXT = ''),
        (that.purgeTXT = '')
    },
  },
  created() {
    eventBus.$on('createAIAccount', this.createAccount)
    eventBus.$on('updateCurrentAIAccount', this.updateAccout)
  },
  beforeDestroy() {
    eventBus.$off('createAIAccount', this.createAccount)
    eventBus.$off('updateCurrentAIAccount', this.updateAccout)
  },
  destroyed() {
    eventBus.$off('createAIAccount', this.createAccount)
    eventBus.$off('updateCurrentAIAccount', this.updateAccout)
  },
  mounted() {
    this.loadUsers()
    const that = this
    if (this.actionType == 'new') {
      this.aiAccount = {
        name: '',
        confJson: {
          owner: {
            fname: '',
            lname: '',
            email: '',
            password: '',
          },
          expiryDate: '2021/12/31',
          serverLocation: 'China',
          accountType: 'Free',
          status: true,
          historicalDataLimit: 365,
          MAULimit: 10000,
          projectLimit: 10,
          reports: [],
          additionAPI: 2,
          export: true,
          annotations: true,
          calculations: true,
          companyBinding: true,
          agencyAdminView: true,
          autoActivate: true,
          systemSetting: {
            dateRange: 'thisMonth',
            language: 'en_US',
            timeZone: 'Asia/Shanghai',
          },
          confirmAdminUserEmail: true,
          adminWebsiteUrl: '',
        },
        projects: [],
      }
    } else {
      this.loadAccount()
    }
    ;(that.password = ''),
      (that.active = true),
      (that.disableTXT = ''),
      (that.activateTXT = ''),
      (that.purgeTXT = '')
      
  },
}
