import { render, staticRenderFns } from "./createUser.vue?vue&type=template&id=feb92206&"
import script from "./createUser.vue?vue&type=script&lang=js&"
export * from "./createUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QItemSection,QAvatar,QCardSection,QInput,QBtn,QCheckbox,QList,QItem,QItemLabel,QTable,QTr,QTd,QIcon,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItemSection,QAvatar,QCardSection,QInput,QBtn,QCheckbox,QList,QItem,QItemLabel,QTable,QTr,QTd,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
