import API from '@/plugins/API';
import * as _ from 'underscore';

export const getActiveAIAccounts = ({ rootState, commit, dispatch }, data) => {
  const { accountId } = rootState.config;
  const evoUser = rootState.auth.id;
  const evoToken = rootState.auth.userToken;
  const currentUser = rootState.auth.currentUser
  if(data.accountId){
    return new Promise((resolve, reject) => {
      API().get(`/evoai/admin/account/${data.accountId}/config?item=aiAccount`).then((res) => {debugger

        if (res.data.new_token) {
          commit('SET_AUTH_TOKEN', res.data.new_token);
        }
        if (res.data.errcode == 40005) {
          dispatch('userLogout');
        } else if (res.data.errcode == 40013) {
          let requestType, requestId
          if(_.contains(currentUser.confJson.aiProjects , '*')){
            requestType = 'account'
            requestId = currentUser.accountId
          }else{
            requestType = 'project'
            requestId = currentUser.confJson.aiProjects[0]
          }
          API().get(`/evoai/admin/${requestType}/${requestId}/config?item=aiAccount&value=${rootState.auth.currentUser.accountId}`).then((sres) => {            
          const totalProjects = sres.data.projects.length;
            if (sres.data.errcode == 0) {
              if (res.data.new_token) {
                commit('SET_AUTH_TOKEN', res.data.new_token);
              }
              sres.data.accountId = sres.data.id;
              const orderedAccounts = [sres.data];
              const aiAccounts = [];
              _.forEach(orderedAccounts, (account) => {
                const projects = []
                _.forEach(account.projects, (project) => {
                  project.configuration = progressBarPercentage(project);
                  if (project.confJson.hasOwnProperty('avatarResourceId')) {
                    project.hasAvatar = true;
                    project.hasAvatarURL = `https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${project.confJson.avatarResourceId}&project=${accountId}&evouser=${evoUser}&evotoken=${evoToken}`;
                  } else {
                    project.hasAvatar = false;
                  }
                  if(_.contains(currentUser.confJson.aiProjects , '*')){
                    projects.push(project)
                  }else{
                    if(_.contains(currentUser.confJson.aiProjects , project.id)){
                      projects.push(project)                  
                    }
                  }
                  account.projects = projects
                  account.totalLength =totalProjects
                });
                aiAccounts.push(account);
              
              });
              if(aiAccounts[0].projects.length > 0){
                dispatch('updateActiveAIAccount', aiAccounts[0].projects[0]);
              }
              commit('SET_AI_ACCOUNTS', aiAccounts);
              resolve(aiAccounts);
            } else if (res.data.errcode == 40005) {
              dispatch('userLogout');
            } else {
              commit('SET_AI_ACCOUNTS', []);
              reject(res.data);
            }
          });
        } else {
          const orderedAccounts = [];
          const aiAccounts = [];
          const { orderedKeys } = res.data;
          orderedKeys.forEach(((key, index) => {
            const item = res.data.data[key];
            item.index = index;
            item.accountId = item.id;
            orderedAccounts.push(item);
          }));
          _.forEach(orderedAccounts, (account) => {
              const projects = []
              _.forEach(account.projects, (project) => {
                project.configuration = progressBarPercentage(project);
                if (project.confJson.hasOwnProperty('avatarResourceId')) {
                  project.hasAvatar = true;
                  project.hasAvatarURL = `https://${rootState.general.locationPrefix}.livecom.cn/evoai/tools/file_gateway.jsp?fileId=${project.confJson.avatarResourceId}&project=${accountId}&evouser=${evoUser}&evotoken=${evoToken}`;
                } else {
                  project.hasAvatar = false;
                }
                if(_.contains(currentUser.confJson.aiProjects , '*')){
                  projects.push(project)
                }else{
                  if(_.contains(currentUser.confJson.aiProjects , project.id)){
                    projects.push(project)
                  }
                }
                account.projects = projects
            });
            aiAccounts.push(account);
          });
          const allowedAccount = _.find(aiAccounts, (account) => account.id == accountId);
          dispatch('updateActiveAIAccount', allowedAccount);
          if(allowedAccount.projects.length > 0){
            dispatch('updateActiveAIAccount', allowedAccount.projects[0]);
          }
          commit('SET_AI_ACCOUNTS', aiAccounts);
          resolve(aiAccounts);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }
};

export const fetchAIAccount = ({ rootState, commit, dispatch }, id) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiAccount&value=${id}`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const createNewAIAccount = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiAccount&value=0`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const updateAIAccount = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiAccount&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const disableAIAccount = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiAccount&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    //resolve err as backend have delete issue
    resolve();
  });
});

export const deleteAIACCOUNTCOMPLETELY = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/account/${rootState.config.accountId}/config?item=aiAccountPurge&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    //resolve err as backend have delete issue
    resolve();
  });
});

export const updateActiveAIAccount = ({ commit, dispatch }, data) => {
  if (data !== undefined) {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_CURRENT_AI_ACCOUNT', data);
        commit('SET_CURRENT_REQUEST_TYPE', 'account');
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }
};

export const updateActiveAIProject = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  commit('SET_CURRENT_AI_PROJECT', data);
  commit('SET_CURRENT_REQUEST_TYPE', 'project');
  commit('SET_PROJECT_ID', data.id);
  resolve(data);
}).catch((err) => {
  reject(err);
});

export const updateMessageStatus = ({ commit, dispatch }, data) => {
  const { id } = data;
  delete data.id;
  return new Promise((resolve, reject) => {
    API().post(`/evoai/admin/project/${data.projectId}/config?item=aiMessage&value=${data.message.id}`, data.message).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
};

export const requestSpecificSession = ({  rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  const { timeZone } = rootState.auth.currentUser.confJson;
  if (data.projectId !== undefined && data.sessionId !== undefined) {
    API().get(`/evoai/admin/project/${data.projectId}/config?item=aiSession&value=${data.sessionId}`).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      const session = res.data;
      session.starred = _.some(session.messages, (message) => message.starred);
      session.flagged = _.some(session.messages, (message) => message.reviewStatus == 'flagged');
      session.reviewed = _.some(session.messages, (message) => message.reviewStatus == 'reviewed');
      session.timeZone = timeZone
      resolve(session);
    }).catch((err) => {
      reject(err);
    });
  } else {
    reject();
  }
});


export const updateAISession=({ rootState, commit, dispatch }, data) => {
  return new Promise((resolve , reject) => {
    API().post(`/evoai/admin/project/${data.aiAccount}/config?item=aiSession&value=${data.session.id}` , data.session).then((res) => {
      if (res.data.new_token) {
        commit('SET_AUTH_TOKEN', res.data.new_token);
      }
      if (res.data.errcode == 40005) {
        dispatch('userLogout');
      }
      if(res.data.errcode === 0){
        resolve(res.data)
      }else{
        reject(res.data)
      }
    })
  })
}
function progressBarPercentage(project) {
  if (typeof project === 'object') {
    let percentage = 0;
    if (project.name !== '') {
      percentage += 8;
    }
    if (project.hasOwnProperty('confJson')) {
        if(project.confJson.hasOwnProperty('industry') && project.confJson.hasOwnProperty('industry') != ''){
          percentage = percentage + 13
        }
        if(project.confJson.hasOwnProperty('environment') && project.confJson.hasOwnProperty('environment') != ''){
          percentage = percentage + 12
        }
        // if(project.confJson.hasOwnProperty('avatarResourceId')){
        //   percentage = percentage + 10
        // }
        if (project.confJson.hasOwnProperty('channelCount') && project.confJson.channelCount > 0) {
          percentage += 34;
        }
    }
    let vendorsArray = project?.vendorlist?.split('+') || []
    let compactedArray =  _.compact(vendorsArray)
    if (compactedArray.length > 0) {
      percentage += 33;
    }
    return {
      progressBarInfo: percentage / 100,
      percentage,
    };
  }
  return 0;
}
