import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import * as _ from 'underscore';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  component: Home,
  meta: {
    title: 'Welcome to Evo Dynamics',
  },
  beforeEnter: requireAuth,
  children: [
    {
      path: '',
      name: 'Home',
      meta: {
        title: 'Welcome to Evo Dynamics',
      },
      component: () => import('../pages/Overview/home.vue'),
    },

    {
      path: 'conversions/goals',
      name: 'conversionsgoals',
      meta: {
        title: 'Conversions Goals',
      },
      component: () => import('../pages/conversions/goal.vue'),
    },
    {
      path: 'conversions/funnel',
      name: 'conversionsfunnel',
      meta: {
        title: 'Conversions Funnel',
      },
      component: () => import('../pages/conversions/funnel.vue'),
    },
    {
      path: 'audience/behaviour',
      name: 'behaviour',
      meta: {
        title: 'Behaviour',
      },
      component: () => import('../pages/audience/behaviour.vue'),
    },
    {
      path: 'audience/trends',
      name: 'trends',
      meta: {
        title: 'Trends',
      },
      component: () => import('../pages/audience/trends.vue'),
    },
    {
      path: 'audience/profile',
      name: 'deepUserProfile',
      meta: {
        title: 'Deep User Profile',
      },
      component: () => import('../pages/audience/deepUserProfile.vue'),
    },
    //version 1.0
    // {
    //   path: 'audience/messages',
    //   name: 'messages',
    //   meta: {
    //     title: 'Messages',
    //   },
    //   component: () => import('../pages/audience/activity/messages.vue'),
    // },
    // {
    //   path: 'audience/duration',
    //   name: 'duration',
    //   meta: {
    //     title: 'Chat Duration',
    //   },
    //   component: () => import('../pages/audience/activity/duration.vue'),
    // },
    // {
    //   path: 'audience/sessions',
    //   name: 'sessions',
    //   meta: {
    //     title: 'Chat Sessions',
    //   },
    //   component: () => import('../pages/audience/activity/sessions.vue'),
    // },

    //version 1.5
    {
      path: 'audience/activity',
      meta: {
        title: 'Activity',
      },
      component: () => import('../pages/audience/activity.vue'),
      children: [
        {
          path: '',
          name: 'sessions',
          meta: {
            title: 'Sessions',
          },
          component: () => import('../pages/audience/activity_1_5/session.vue'),
        },
        {
          path: 'messages',
          name: 'messages',
          meta: {
            title: 'Messages',
          },
          component: () => import('../pages/audience/activity_1_5/messages.vue'),
        },
      ],
    },
    {
      path: 'audience/Retention',
      name: 'Retention',
      meta: {
        title: 'Retention',
      },
      component: () => import('../pages/audience/retention.vue'),
    },
    {
      path: 'audience/engagement',
      name: 'Engagement',
      meta: {
        title: 'Engagement',
      },
      component: () => import('../pages/audience/engagement.vue'),
    },
    {
      path: 'sales/report',
      name: 'report',
      meta: {
        title: 'Sales Scripts',
      },
      component: () => import('../pages/conversions/report.vue'),
    },
    {
      path: 'conversations/chat',
      name: 'chat',
      meta: {
        title: 'Chat History',
      },
      component: () => import('../pages/conversations/chat.vue'),
    },
    {
      path: 'conversations/network',
      name: 'network',
      meta: {
        title: 'Network',
      },
      component: () => import('../pages/conversations/network.vue'),
    },
    {
      path: 'conversations/topics/overview',
      name: 'topics',
      meta: {
        title: 'Topics',
      },
      component: () => import('../pages/conversations/topics/overview.vue'),
    },
    {
      path: 'conversations/topics/drilldown',
      name: 'drilldown',
      meta: {
        title: 'Topics Drilldown',
      },
      component: () => import('../pages/conversations/topics/drilldown.vue'),
    },
    {
      path: 'conversations/intents/drilldown',
      name: 'intentsDrilldown',
      meta: {
        title: 'Intents Drilldown',
      },
      component: () => import('../pages/conversations/intents/drilldown.vue'),
    },
    {
      path: 'conversations/intents/overview',
      name: 'intentsoverview',
      meta: {
        title: 'Intents Overview',
      },
      component: () => import('../pages/conversations/intents/overview.vue'),
    },
    {
      path: 'conversations/parameters/overview',
      name: 'parametersOverview',
      meta: {
        title: 'Parameters Overview',
      },
      component: () => import('../pages/conversations/parameters/overview.vue'),
    },
    {
      path: 'conversations/parameters/drilldown',
      name: 'parametersDrilldown',
      meta: {
        title: 'Parameters Drilldown',
      },
      component: () => import('../pages/conversations/parameters/drilldown.vue'),
    },
    {
      path: 'conversations/flow',
      name: 'flowPlain',
      meta: {
        title: 'Conversations Flows',
      },
      component: () => import('../pages/conversations/flow.vue'),
    },
    {
      path: 'conversations/messages',
      name: 'conversationsMessages',
      meta: {
        title: 'Messages',
      },
      component: () => import('../pages/conversations/messages.vue'),
    },
    {
      path: 'agent/workload',
      name: 'workload',
      meta: {
        title: 'Agent Workload',
      },
      component: () => import('../pages/agentCare/workload.vue'),
    },
    {
      path: 'AI/Validation',
      name: 'AI Validation',
      meta: {
        title: 'AI Validation',
      },
      component: () => import('../pages/agentCare/aivalidation.vue'),
    },
    {
      path: 'evolution/heartbeat',
      name: 'heartbeat',
      meta: {
        title: 'Heartbeat',
      },
      component: () => import('../pages/evolution/heartbeat.vue'),
    },
    {
      path: 'evolution/AIfallbacks',
      name: 'AIfallbacks',
      meta: {
        title: 'AI Fallbacks',
      },
      component: () => import('../pages/evolution/AIfallbacks.vue'),
    },
    {
      path: 'evolution/performance/table',
      name: 'AIPerformanceTable',
      meta: {
        title: 'AI Performance (Table)',
      },
      component: () => import('../pages/evolution/performance/table.vue'),
    },
    {
      path: 'evolution/performance/chart',
      name: 'AIPerformanceChart',
      meta: {
        title: 'AI Performance (Chart)',
      },
      component: () => import('../pages/evolution/performance/chart.vue'),
    },
  ],
},
{
  path: '/projects',
  meta: {
    title: 'AI Projects',
  },
  beforeEnter: requireAuth,
  component: () => import('../views/Project.vue'),
  children: [,
    {
      path: '',
      name: 'aiProjects',
      meta: {
        title: 'My Projects',
      },
      component: () => import('../pages/aiProject/index.vue'),
    },
  ],
},
{
  path: '/welcome',
  alias: '/Auth',
  meta: {
    title: 'Welcome to Evo Dynamics',
  },
  component: () => import('../views/Auth.vue'),
  beforeEnter: isLoggedOut,
  children: [,
    {
      path: '',
      name: 'index',
      meta: {
        title: 'Welcome to Evo Dynamics',
      },
      component: () => import('../pages/auth/index.vue'),
    },
    {
      path: 'login',
      name: 'login',
      meta: {
        title: 'Login',
      },
      component: () => import('../pages/auth/login.vue'),
    },
    {
      path: 'recover',
      name: 'recover',
      meta: {
        title: 'Recover Password',
      },
      component: () => import('../pages/auth/recover.vue'),
    },
  ],
},
{
  path: '/confirm',
  name: 'confirm',
  meta: {
    title: 'Email Confirmation',
  },
  component: () => import('../pages/auth/confirm.vue'),
},
{
  path: '/confirmedlogin',
  name: 'confirmedlogin',
  meta: {
    title: 'Google Login Confirmation',
  },
  component: () => import('../pages/auth/confirmedlogin.vue'),
},
{
  path: '*',
  redirect: '/projects',
},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


function requireAuth(to, from, next) {
  if (to.name !== from.name) {
    if (!store.getters.getLoginStatus) {
      const userToken = store.getters.getUserToken;
      const userId = store.getters.getUserId;
      if (userToken && userId) {
        store.dispatch('userData', { token: userToken, id: userId });
        next();
      } else {
        next('/Auth'); // if not login then redirect user to auth
      }
    } else {
      next();// if user is already login next()
    }
  }
}

function isLoggedOut(to, from, next) {
  if (!store.getters.getLoginStatus) {
    const userToken = store.getters.getUserToken;
    const userId = store.getters.getUserId;
    if (!userToken || !userId) {
      store.dispatch('userLogout');
      next();
    } else {
      next('/projects');
    }
  } else {
    next('/projects');
  }
}

export default router;
