//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import eventBus from "@/plugins/eventBus";
import navbar from "../components/shared/navbar";
import drawer from "../components/shared/drawer";
import filterbar from "../components/shared/filterbar";
import accountDrawer from "../components/account/drawer";
import projectDrawer from "../components/project/drawer";
import profileDrawer from "../components/account/profileDrawer";

export default {
  name: "Home",
  components: {
    navbar,
    drawer,
    filterbar,
    accountDrawer,
    projectDrawer,
    profileDrawer
  },
  data() {
    return {
      showDrawer: true,
      showProjectDrawer: true,
      showProfileDrawer: true
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      selectedAIAccount: "getCurrentAIAccount"
    }),
    currentRoute(){
      return this.$route.name
    }
  },
  watch: {
    $route() {
      eventBus.triggerProjectDrawer({status: false});
      eventBus.triggerAccountDrawer(false);
      eventBus.triggerProfileDrawer(false);
    }
  }
};
