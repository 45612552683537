//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'createUser',
  props: {
    actionType: {
      required: true,
      default: 'new',
    },
    userToEdit: {
      required: true,
    },
    accountId:{
      required: true,
    }
  },
  components: {
  },
  data() {
    return {
      passwordType: 'password', // can toggle between password and text
      user: {
        accountId: '',
        name: '',
        email: '',
        password: '',
        notify: true,
        confJson: {
          roles: ['admin'],
          language: '',
          timeZone: '',
          dateRange: '',
        },
      },
      userProjects: [],
      currentAIAccount: '',
      // update user features
      delText: '', // delete text
      reset: false, // reset password status
      password: '',
      userToEditId: '',
      userToEdit:'',
      columns: [
        {name: 'name',label: 'Name', align: 'left',  field: 'name',sortable: true},
        { name: 'id', align: 'center', label: 'PROJECT ID', field: 'id', sortable: true },
        { name: 'environment', align: 'center', label: 'ENVIRONMENT', field: 'environment', sortable: true },
        { name: 'createdAt', align: 'center', label: 'CREATION DATE', field: 'date', sortable: true },
      ],
      pagination: {
         page: 1,    
         rowsPerPage: 0 // 0 means all rows    
     }
    };
  },
  computed: {
    ...mapGetters({
      aiAccounts: "getAIAccounts"
    }),
  },
  methods: {
    ...mapActions({
      createNewAdminUser: 'createNewAdminUser',
      deleteUser: 'deleteUser',
      updateAdminUser: 'updateAdminUser',
    }),
    generatePassword() {
      const that = this;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 8; i++) {
        result += characters.charAt( Math.floor(Math.random() * charactersLength));
      }
      that.passwordType = 'text';
      if (that.actionType == 'new') {
        that.user.password = result;
      } else {
        that.password = result;
      }
    },
    createNewUser(accountId) {
      const that = this;
      const { user } = that;
      if (!that.inValid(user.name)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.nameError'),
        });
      } else if (!that.inValid(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.emailError'),
        });
      } else if (!that.inValid(user.password)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.passwordError'),
        });
      } else if (!that.validateEmail(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.invalidEmail'),
        });
      } else if (user.confJson.roles.length < 1) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.permissionErr'),
        });
      } else {
        const new_user = {
          accountId,
          name: user.name,
          email: user.email,
          password: user.password,
          confJson: {
            roles: user.confJson.roles,
            aiProjects: [],
          },
        };
        that.createNewAdminUser(new_user).then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action Successful',
            text: 'New Admin account created successfully',
          });
          that.user = {
            name: '',
            email: '',
            password: '',
            notify: true,
            confJson: { roles: ['admin'] },
          };
          this.$store.commit('SET_USER_DRAWER', false);
        }).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        });
      }
    },
    confirmDelete() {
      const that = this;
      if (that.delText == 'DELETE') {
        that.deleteUser(that.user).then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action Successful',
            text: 'Admin account deleted successfully',
          });
          this.$store.commit('SET_USER_DRAWER', false);
        }).catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          });
        });
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type DELETE and then hit the delete button',
        });
      }
    },
    resetPassword() {
      this.reset = true;
    },
    updateCurrentUser(accountId) {
      const that = this;
      const { user } = that;
      if (!that.inValid(user.name)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.nameError'),
        });
      } else if (!that.inValid(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.emailError'),
        });
      } else if (!that.validateEmail(user.email)) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.invalidEmail'),
        });
      } else if (user.confJson.roles.length < 1) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: that.$t('userManagement.errors.title'),
          text: that.$t('userManagement.errors.permissionErr'),
        });
      } else {
        const update_user = {
          id: that.user.id,
          accountId,
          name: user.name,
          email: user.email,
          confJson: user.confJson,
        };
        if (that.reset == true) {
          if (!that.inValid(that.password)) {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: that.$t('userManagement.errors.title'),
              text: that.$t('userManagement.errors.passwordError'),
            });
          } else {
            update_user.password = that.password;
            that.updateAdminUser(update_user).then((res) => {
              this.$notify({
                group: 'notifications',
                type: 'success',
                title: 'Action Successful',
                text: 'Admin account updated successfully',
              });
              this.$store.commit('SET_USER_DRAWER', false);
            }).catch((err) => {
              this.$notify({
                group: 'notifications',
                type: 'error',
                title: 'Action Error',
                text: err.errmsg,
              });
            });
          }
        } else {
          that.updateAdminUser(update_user).then((res) => {
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action Successful',
              text: 'Admin account updated successfully',
            });
            this.$store.commit('SET_USER_DRAWER', false);
          }).catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            });
          });
        }
      }
    },
    getUserProjects(){
      let that = this
      let currentUser = that.user
      let userAIAccount =  _.find(that.aiAccounts, account => account.id == that.user.accountId);
      let targetProjects = userAIAccount.projects
      if(currentUser.confJson.aiProjects.length > 0){
        if(_.contains(currentUser.confJson.aiProjects, '*')){
          that.userProjects = targetProjects.map((project) => {
          project.date = moment(project.created_time).format('DD MMM YYYY');
          project.environment = project.confJson.environment;
          return project
        });
        }else{
          let assaingedProjects = currentUser.confJson.aiProjects
          let allowedProject = []
          _.forEach(targetProjects , (project) =>{
            if(_.contains(assaingedProjects, project.id)){
              project.date = moment(project.created_time).format('DD MMM YYYY');
              project.environment = project.confJson.environment;
              allowedProject.push(project)
            }
          })
          that.userProjects = allowedProject
        }
      }else{
        
      }
    }
  },
  watch: {
    userToEdit(newVal) {
      const that = this;
      that.user = newVal;
      this.getUserProjects()
      that.user.notify = true;
    },
  },
  created() {
    eventBus.$on('createUser', this.createNewUser);
    eventBus.$on('updateUser', this.updateCurrentUser);
  },
  beforeDestroy() {
    eventBus.$off('createUser', this.createNewUser);
    eventBus.$off('updateUser', this.updateCurrentUser);
  },
  destroyed() {
    eventBus.$off('createUser', this.createNewUser);
    eventBus.$off('updateUser', this.updateCurrentUser);
  },
  mounted() {
    const that = this;
    if (this.actionType == 'edit') {
      this.userToEditId = this.user.accountId;
      this.user = this.userToEdit;
      this.getUserProjects()
      this.user.notify = true;
    }
  },
};
