import { render, staticRenderFns } from "./noData.vue?vue&type=template&id=bfbde05a&scoped=true&"
import script from "./noData.vue?vue&type=script&lang=js&"
export * from "./noData.vue?vue&type=script&lang=js&"
import style0 from "./noData.vue?vue&type=style&index=0&id=bfbde05a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfbde05a",
  null
  
)

export default component.exports
import {QCard,QCardSection,QAvatar,QBtn,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QAvatar,QBtn,QSpinnerIos})
