import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  aiSessions: [],
  aiSessionsList: [],
  profilingStats: [],
};
export default {
  state,
  actions,
  mutations,
  getters,
};
