const { localStorage } = window;
const Storage = {
  getItem(key) {
    try {
      const data = localStorage.getItem(key);
      return data;
    } catch (e) {
      console.log(`unable to get data for ${key} , ${e}`);
    }
  },
  setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log(`unable to get data for ${key} , ${e}`);
    }
  },
  clearAll() {
    return localStorage.clear();
  },
};

export default Storage;
