module.exports = {
  SET_LOADER_STATUS: (state, status) => {
    if (status !== undefined) {
      state.showLoader = status;
    } else {
      state.showLoader = !state.showLoader;
    }
  },
  SET_FILTER_DATE: (state, date) => {
    state.filterDate = date;
  },
  SET_DARK_THEME: (state, status) => {
    state.darkTheme = status;
  },
};
