module.exports = {
  SET_AI_ACCOUNTS: (state, list) => {
    state.aiAccounts = list;
  },
  SET_CURRENT_AI_ACCOUNT: (state, account) => {
    state.currentAIAccount = account;
  },
  SET_CURRENT_AI_PROJECT: (state, project) => {
    state.currentAIAccount = project;
  },
  SET_CURRENT_REQUEST_TYPE: (state, reqType) => {
    state.requestType = reqType;
  },
  SET_ACCOUNT_ID: (state, id) => {
    state.accountId = id;
  },
  SET_PROJECT_ID: (state, id) => {
    state.projectId = id;
  },
  SET_AI_ACCOUNT_DRAWER: (state, status) => {
    state.aiAccountDrawer = status;
  }
};
