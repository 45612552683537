//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import Storage from "@/plugins/Storage";

export default {
  name: "LayoutDefault",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      darkTheme: "getDarkTheme"
    })
  },
  watch: {
    $route(to, from) {
      document.title = `Evo Dynamics | ${to.meta.title}` || "Welcome to Evo Dynamics";
      if (from.name && from.name !== to.name) {
        if (from.name !== "aiProjects" && to.name !== "aiProjects") {
          this.$router.replace({
              query: {
                ...from.query
              }
            }).catch(() => {});
        }
      }
    }
  },
  mounted() {
    this.$store.commit("SET_CHANNEL_DRAWER", false);
    const theme = Storage.getItem("theme");
    if (this.darkTheme === true || theme === "dark") {
      const { body } = document;
      body.classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
  }
};
