import axios from 'axios';
import store from '@/store';
export default () => {
  const headers = {
    evotoken: store.getters.getUserToken,
    evouser: store.getters.getUserId,
  };
  return axios.create({
    headers,
    baseURL: `https://${store.getters.getLocationPrefix}.livecom.cn`,
  });
};
