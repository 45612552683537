import * as _ from 'underscore';
import API from '@/plugins/API';
import moment from 'moment';

export const createNewAdminUser = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().post(`/evoai/admin/account/${rootState.config.accountId}/config?item=adminUser&value=0`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res.data);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const updateAdminUser = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => { debugger
  API().post(`/evoai/admin/account/${rootState.config.accountId}/config?item=adminUser&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve(res);
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const deleteUser = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().delete(`/evoai/admin/account/${rootState.config.accountId}/config?item=adminUser&value=${data.id}`, data).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 0) {
      resolve();
    } else if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    //resolve err as backend have delete issue
    resolve();
  });
});

export const listAllUsers = ({ commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/account/${data.accountId}/config?item=adminUser`).then((res) => {
    if (res.data.new_token) {
      commit('SET_AUTH_TOKEN', res.data.new_token);
    }
    if (res.data.errcode == 40005) {
      dispatch('userLogout');
    } else {
      const orderedArr = [];
      const orderedKeys = res.data.orderedKeys || [];
      orderedKeys.forEach(((key, index) => {
        const item = res.data.data[key];
        const pending = _.contains(item.confJson.roles, 'pending');
        if (pending) {
          item.status = 'Pending';
        } else {
          item.status = 'Active';
        }
        item.date = moment(item.created_time).format('DD MMM YYYY');
        item.role = _.reject(item.confJson.roles, (role) => role == 'pending')[0];
        if (item.role == '*') {
          item.role = 'Superuser';
        } else {
          item.role = item.role.charAt(0).toUpperCase() + item.role.slice(1);
        }
        orderedArr.push(item);
      }));
      const adminList = _.filter(orderedArr, (user) => user.role !== 'Superuser');
      resolve(adminList);
    }
  }).catch((err) => {
    reject(err);
  });
});

export const verifyUserEmail = ({ rootState, commit, dispatch }, data) => new Promise((resolve, reject) => {
  API().get(`/evoai/admin/confirm?uid=${data.uid}&token=${data.token}`, data).then((res) => {
    if (res.data.errcode == 0) {
      resolve(res);
    } else {
      reject(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});
