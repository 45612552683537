//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'createUserModal',
  props: {    
    isUserModal: {
      required: true,
      default: false,
    },   
  },
  components: {},
  data() {
    return {
      user: {name: ''},
       model: null,
        options: ['Admin', 'Reporter', 'Content Editor'],      
    }
  },
  methods: {
    closeModal(){
      this.isUserModal = false
      this.$parent.addNewUser(false)
    },
  },
}
