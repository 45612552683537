//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// cross tab communication
import languages from '@/data/language.json'
import { mapGetters, mapActions } from 'vuex'
import * as _ from 'underscore'

const sysend = require('sysend')

export default {
  name: 'aiVendorsDrawerComponent',
  props: {
    actionType: {
      required: true,
      default: 'new',
    },
    currentAIProject: {
      required: true,
      type: Object,
    },
    currentAIVendorID: {
      type: Number,
    },
  },
  data() {
    return {
      languagesList: languages,
      showInnerLoading: false,
      showButton: true,
      createVendorLoading: false,
      updateAIVendorLoading: false,
      intentsLoading: false,
      showConnect: false,
      currentAIAccount: '',
      boxShadow: '',
      showGoogleInfo: false,
      selectedAiVendor: null,
      rasaAIVendor: {
        server: '',
        port: '',
        secrettoken: '',
      },
      currentAIVendor: {
        openid: '',
        aiType: '',
        server: '',
        port: '',
        url: '',
        access_token: null,
        intentList: [],
        confJson: {
          topicSeparator: '',
          intents: {
            welcome: [],
            defaultFallback: [],
            humanHandover: '',
            goals: [],
            funnels: [],
          },
        },
      },
      aiProviders: [
        {
          label: 'RASA',
          value: 'RASA',
        },
        {
          label: 'Google Dialogflow',
          value: 'Dialogflow',
        },
        {
          label: 'IBM Watson',
          value: 'IBMWatson',
          inactive: true,
        },
      ],
      user_projects: [],
      providerLable: 'Select a provider',
      projectLable: 'Select a project',
      delText: '',
      confirmFileDelete: false,
      language: null,
      defaultLanguage: null,
      outerWidth: 85,
    }
  },
  computed: {
    ...mapGetters({
      selectedAIAccount: 'getCurrentAIAccount',
      aiVendorsDrawer: 'getAIVendorsDrawer',
      AIProjectToEdit: 'getAIProjectToEdit',
      getFilterDate: 'getFilterDate',
      darkTheme: 'getDarkTheme',
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth)
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth)
    },
    project_parsed() {
      const parse_list = _.map(this.user_projects, (project) => ({
        label: project.name.charAt(0).toUpperCase() + project.name.slice(1),
        value: project.projectId,
      }))
      return _.sortBy(parse_list, 'label')
    },
  },
  methods: {
    ...mapActions({
      addNewAIVendor: 'addNewAIVendor',
      requestAIVendor: 'requestAIVendor',
      updateAIProject: 'updateAIProject',
      deletAIVendor: 'deletAIVendor',
      updateAIVendor: 'updateAIVendor',
      requestGoogleURLforAIVendor: 'requestGoogleURLforAIVendor',
    }),
    closeDrawer() {
      setTimeout(() => {
        this.$store.commit('SET_AIVENDORS_DRAWER', false)
      }, 100)
    },
    getGoogleAccessURL() {
      const that = this
      that.showInnerLoading = true
      const data = {
        accountId: that.selectedAIAccount.accountId,
        code: '',
      }
      that
        .requestGoogleURLforAIVendor(data)
        .then((res) => {
          const { url } = res
          window.open(url, '', ',top=50,left=300,width=600,height=700;')
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
        .finally(() => {
          setTimeout(() => {
            that.showInnerLoading = false
          }, 500)
        })
    },
    googleProjectSelected(vendorData) {
      const that = this
      that.showInnerLoading = true
      if (that.actionType == 'new') {
        const data = {
          accountId: that.selectedAIAccount.accountId,
          code: vendorData.code,
        }
        that
          .requestGoogleURLforAIVendor(data)
          .then((res) => {
            that.user_projects = res.projects
            that.thirdPartyCredentials = res.thirdPartyCredentials
            that.showInnerLoading = false
            that.showButton = false
            that.showConnect = true
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
          .finally(() => {
            that.showInnerLoading = false
          })
      }
    },
    creatNewVendor(vendorData) {
      const that = this
      that.showInnerLoading = true
      const data = {
        projectId: that.currentAIProject.id,
        vendorData: {
          access_token: that.currentAIVendor.access_token,        
          name: that.currentAIVendor.aiType.label == 'RASA' ? 'Rasa Integration':that.currentAIVendor.name,
          url:`${that.currentAIVendor.server}:${that.currentAIVendor.port}`,
          // created_time:'',
          // last_updated_time:'',
          accountId: that.currentAIProject.accountId,
          // openid: that.currentAIVendor.openid,
          openid: that.currentAIVendor.access_token,
          aiType: that.currentAIVendor.aiType.value,
          confJson: {
            thirdPartyCredentials: that.thirdPartyCredentials,
            topicSeparator: '',
            intents: {
              welcome: [],
              defaultFallback: [],
              humanHandover: '',
              goals: [],
              funnels: [],
            },
          },
        },
      }
      that
        .addNewAIVendor(data)
        .then((res) => {
          that.updateAIProjectForAIVendor(res.data.resourceId)
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
          that.showInnerLoading = false
        })
    },
    fetchVendorData(vendorId, from) {debugger
      const that = this
      if (from == 'intent') {
        that.intentsLoading = true
      }
      that.createVendorLoading = true
      that.showInnerLoading = true
      that
        .requestAIVendor({ id: vendorId, projectId: that.currentAIProject.id })
        .then((res) => {debugger
          const aiVendor = res.data
          if (!aiVendor.confJson.hasOwnProperty('intents')) {
            aiVendor.confJson.intents = {
              welcome: [],
              defaultFallback: [],
              humanHandover: '',
              goals: [],
              funnels: [],
            }
          } else {
            if (
              aiVendor.confJson.intents.welcome &&
              typeof aiVendor.confJson.intents.welcome == 'string'
            ) {
              let currentItent = aiVendor.confJson.intents.welcome
              aiVendor.confJson.intents.welcome = []
              aiVendor.confJson.intents.welcome.push(currentItent)
            } else if (aiVendor.confJson.intents.welcome === '') {
              aiVendor.confJson.intents.welcome = []
            }
            if (
              aiVendor.confJson.intents.defaultFallback &&
              typeof aiVendor.confJson.intents.defaultFallback == 'string'
            ) {
              let currentItent = aiVendor.confJson.intents.defaultFallback
              aiVendor.confJson.intents.defaultFallback = []
              aiVendor.confJson.intents.defaultFallback.push(currentItent)
            } else if (aiVendor.confJson.intents.defaultFallback === '') {
              aiVendor.confJson.intents.defaultFallback = []
            }
          }
          if (!aiVendor.confJson.hasOwnProperty('languageList')) {
            aiVendor.confJson.languageList = []
          }
          if (!aiVendor.confJson.hasOwnProperty('topicSeparator')) {
            aiVendor.confJson.topicSeparator = '.'
          }
          if (!aiVendor.confJson.hasOwnProperty('languageCode')) {
            this.defaultLanguage = _.find(this.languagesList, function (lang) {
              return lang.code === 'en-US'
            }).name
          } else {
            this.defaultLanguage = _.find(this.languagesList, function (lang) {
              return lang.code === aiVendor.confJson.languageCode
            }).name
          }
          const data = {
            projectId: that.currentAIProject.id,
            startdate: that.getFilterDate.start,
            enddate: that.getFilterDate.end,
            slotMins: 60,
          }
          that.currentAIVendor = aiVendor
          var url = res.data.url
          that.currentAIVendor.server = url.substr(0, url.indexOf(':'))
          that.currentAIVendor.port = url.split(':')[1]
        })
        .finally(() => {
          that.actionType = 'edit'
          setTimeout(() => {
            that.createVendorLoading = false
            that.showInnerLoading = false
            that.intentsLoading = false
          }, 500)
        })
    },
    updateAIProjectForAIVendor(vendorId) {debugger
      const that = this
      that
        .requestAIVendor({
          id: vendorId,
          projectId: that.currentAIProject.id,
        })
        .then((vendor) => {debugger
          const { AIProjectToEdit } = that
          const vendorListArr = AIProjectToEdit.vendorlist.split('+')
          if (vendorListArr.length == 0) {
            AIProjectToEdit.vendorlist = vendor.data.openid
          } else if (!_.contains(vendorListArr, vendor.data.openid)) {
            vendorListArr.push(vendor.data.openid)
            let updatedArray = _.compact(vendorListArr)
            AIProjectToEdit.vendorlist = updatedArray.join('+')
          }
          that
            .updateAIProject({
              accountId: that.AIProjectToEdit.accountId,
              project: that.AIProjectToEdit,
            })
            .then((res) => {debugger
              that.fetchVendorData(vendorId)
            })
            .finally(() => {
              that.showInnerLoading = false
            })
        })
    },
    deleteAIVendorFromProject(vendorName) {
      const that = this
      const { AIProjectToEdit } = that
      const vendorListArr = AIProjectToEdit.vendorlist.split('+')
      if (vendorListArr.length > 0) {
        if (_.contains(vendorListArr, vendorName)) {
          const updatedArray = _.reject(
            vendorListArr,
            (vendor) => vendor == vendorName,
          )
          let vendorlist = _.compact(updatedArray)
          AIProjectToEdit.vendorlist = vendorlist.join('+')
          that
            .updateAIProject({
              accountId: that.AIProjectToEdit.accountId,
              project: that.AIProjectToEdit,
            })
            .then((res) => {})
        }
      }
    },
    confirmDelete() {
      const that = this
      if (that.delText == 'DELETE') {
        that
          .deletAIVendor(that.currentAIVendor)
          .then((res) => {
            that.deleteAIVendorFromProject(that.currentAIVendor.name)
            this.$notify({
              group: 'notifications',
              type: 'success',
              title: 'Action successful',
              text: 'AI Vendor deleted successfully',
            })
            that.closeDrawer()
          })
          .catch((err) => {
            this.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Action Error',
              text: err.errmsg,
            })
          })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Invalid Data',
          text: 'type DELETE and then hit the delete button',
        })
      }
    },
    updateCurrentAIVendor() {
      const that = this
      const data = {
        projectId: that.currentAIProject.id,
        vendorData: that.currentAIVendor,
      }
      that.updateAIVendorLoading = true
      that
        .updateAIVendor(data)
        .then((res) => {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Action successful',
            text: `AI Vendor ${data.vendorData.thirdpartyId} Updated`,
          })
          that.updateAIProjectForAIVendor(res.data.resourceId)
        })
        .catch((err) => {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: 'Action Error',
            text: err.errmsg,
          })
        })
        .finally(() => {
          that.fetchVendorData(data.vendorData.id)
          that.updateAIVendorLoading = false
        })
    },
    showDeletePopup() {
      const that = this
      if (that.currentAIVendor.access_token !== '') {
        that.confirmFileDelete = true
      }
    },
    deleteLanguage(language) {
      const that = this
      that.currentAIVendor.confJson.languageList = _.filter(
        that.currentAIVendor.confJson.languageList,
        (currentLanguage) => currentLanguage.code !== language.code,
      )
    },
  },
  watch: {
    aiVendorsDrawer(newVal) {
      const that = this
      if (newVal) {
        that.boxShadow = '-15vw 0px 0px 20px rgba(0,0,0,0.4)'
      } else {
        that.boxShadow = ''
      }
      if (that.actionType == 'edit') {
        that.delText = ''
        that.currentAIAccount = _.find(
          that.aiAccounts,
          (account) => account.id == that.user.accountId,
        )
        that.showButton = false
        that.fetchVendorData(that.currentAIVendorID)
      } else {
        that.showButton = true
        that.currentAIVendor = {
          openid: '',
          aiType: '',
          access_token: null,
          intentList: [],
          confJson: {
            intents: {
              welcome: [],
              defaultFallback: [],
              humanHandover: '',
              goals: [],
              funnels: [],
            },
          },
        }
        that.currentAIAccount = _.find(
          that.aiAccounts,
          (account) => account.id == that.adminAIAccount.id,
        )
      }
    },
    selectedAiVendor: {
      deep: true,
      handler(newVal) {
        const that = this
        that.currentAIVendor.openid = newVal.value
        that.currentAIVendor.name = newVal.label
      },
    },
    currentAIVendor: {
      deep: true,
      handler(newVal) {
        const that = this
        if (newVal.aiType !== '') {
          if (this.actionType == 'new') {
            that.currentAIVendor.aiType = newVal.aiType
          }
          that.providerLable = ''

          that.showGoogleInfo = true
        } else {
          that.providerLable = 'Select a provider'
          that.showGoogleInfo = false
        }
        if (newVal.openid !== '') {
          that.projectLable = ''
          that.showConnect = true
        } else {
          that.projectLable = 'Select a Project'
        }
      },
    },
    language(newVal) {
      if (newVal != null) {
        this.currentAIVendor.confJson.languageList.push(newVal)
        this.language = null
      }
    },
    defaultLanguage(newVal) {
      if (newVal != null && typeof newVal === 'object') {
        this.currentAIVendor.confJson.languageCode = newVal.code
        this.defaultLanguage = newVal.name
      }
    },
  },
  mounted() {
    const that = this
    that.delText = ''
    if (that.actionType == 'edit') {
      that.currentAIAccount = _.find(
        that.aiAccounts,
        (account) => account.id == that.user.accountId,
      )
      that.showButton = false
      that.fetchVendorData(that.currentAIVendorID)
    } else {
      that.currentAIVendor.aiType = ''
      that.currentAIAccount = _.find(
        that.aiAccounts,
        (account) => account.id == that.adminAIAccount.id,
      )
      that.showButton = true
    }
  },
  created() {
    sysend.on('triggerGoogleProjectSelect', this.googleProjectSelected)
  },
  beforeDestroy() {
    sysend.off('triggerGoogleProjectSelect', this.googleProjectSelected)
  },
  destroyed() {
    sysend.off('triggerGoogleProjectSelect', this.googleProjectSelected)
  },
}
