module.exports = {
  SET_AI_SESSIONS_FILTERED: (state, list) => {
    state.aiSessions = list;
  },
  SET_AI_SEESIONS_LIST: (state, list) => {
    state.aiSessionsList = list;
  },
  SET_PROFILING_STATS: (state, list) => {
    state.profilingStats = list;
  },
};
