import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const state = {
  projects: [],
  AIVendorsDrawer: false,
  channelDrawer: false,
  aiProjectAccount: {},
  aiProjectToEdit: {},
  editSource:{}
};
export default {
  state,
  actions,
  mutations,
  getters,
};
