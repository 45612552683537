module.exports = {
  SET_USER_LIST: (state, list) => {
    state.userList = list;
  },
  SET_USER_DRAWER: (state, userDrawer) => {
    state.userDrawer = userDrawer;
  },
  SET_PROFILE_DRAWER: (state, status) => {
    state.profileDrawer = status;
  },
  SET_ADMIN_AI_ACCOUNT: (state, account) => {
    state.adminAIAccount = account;
  },
};
