//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import aiAccountDrawer from "../account/aiAccount/aiAccountDrawer.vue";
import moment from 'moment';
import eventBus from "@/plugins/eventBus";
import Storage from '@/plugins/Storage'
import { mdiTranslate } from "@quasar/extras/mdi-v4";
import * as _ from "underscore";

export default {
   components: {
    aiAccountDrawer
  },
  name: "Navbar",
  data() {
    return {
       accountsList: [],
      currentAIAccount: "",
      currentAIProject: "",
      currentAIProjectOBJ: {},
      AccountToEdit: {},
      search: "",
      mdiTranslate: "",
      langs: [
        {
          label: "English",
          value: "en_US"
        },
        {
          label: "Nederlands",
          value: "nl_NL"
        },
        {
          label: "中文",
          value: "zh_CN"
        }
      ],
      active: false,
      style: {
        opacity: 1
      }
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "getLoginStatus",
      aiAccounts: "getAIAccounts",
      currentUser: "getCurrentUser",
      accountId: "getAccountId",
      darkTheme: "getDarkTheme"
    }),
    currentRoute() {
      return this.$router.history.current.name;
    },
    avatarInitial() {
      const str = this.currentAIAccount;
      if (str != undefined) {
        if (str.includes("-")) {
          const first = str[0];
          const indexOF_ = str.lastIndexOf("-");
          return `${first}${str[indexOF_ + 1]}`.toUpperCase();
        }
        return str.substring(0, 2).toUpperCase();
      }
    },
    projectAvatarInitial() {
      const str = this.currentAIProject;
      if (str != undefined && typeof str === "string") {
        if (str.includes("-")) {
          const first = str[0];
          const indexOF_ = str.lastIndexOf("-");
          return `${first}${str[indexOF_ + 1]}`.toUpperCase();
        }
        return str.substring(0, 2).toUpperCase();
      }
    },
    userNameInitial() {
      const str = this.currentUser.name;
      if (str != undefined) {
        if (str.includes("-")) {
          const first = str[0];
          const indexOF_ = str.lastIndexOf("-");
          return `${first}${str[indexOF_ + 1]}`.toUpperCase();
        }
        return str.substring(0, 2).toUpperCase();
      }
    },
    activeAIAccounts() {
      return _.filter(this.aiAccounts, account => {
        if (account.projects.length > 0 && account.status != "disabled") {
          return account;
        }
      });
    }
  },
  methods: {
    ...mapActions({
      getActiveAIAccounts: "getActiveAIAccounts",
      updateActiveAIAccount: "updateActiveAIAccount",
      userLogout: "userLogout"
    }),
    navigateHelp(){
      window.open("https://www.evodynamics.io/help-center/?utm_source=console");
    },
    sendFeedback(){
    window.open("https://www.evodynamics.io/feedback/");
    },
 toggleEdit(account) {
      const that = this;
      this.AccountToEdit = account;
      this.actionType = "edit";
      setTimeout(() => {
        this.$store.commit("SET_AI_ACCOUNT_DRAWER", true);
      }, 200);
    },
    loadAIAccounts() {   
      const that = this;
      this.loading = true;
      const data = {
        accountId: this.currentUser.accountId || 1
      };
      this.getActiveAIAccounts(data).then(res => {
          this.accountsList = _.map(res, account => {
            if (!account.created_time) {
              account.created_time = account.projects[0].created_time;
            }
            if (!account.hasOwnProperty("confJson")) {
              account.confJson = {
                owner: {
                  fname: "",
                  lname: "",
                  email: "",
                  password: ""
                },
                expiryDate: "2021/12/31",
                serverLocation: "China",
                domain: "",
                status: true,
                accountType: "Free",
                historicalDataLimit: 365,
                MAULimit: 10000,
                projectLimit: 10,
                reports: [],
                additionAPI: 2,
                export: true,
                annotations: true,
                calculations: true,
                companyBinding: true,
                agencyAdminView: true,
                systemSetting: {
                  dateRange: "thisMonth",
                  language: "en_US",
                  timeZone: "Asia/Shanghai"
                }
              };
            }
           account.date = moment(account.created_time).format("DD MMM YYYY");
            account.statusText = account.status || "Active";
            account.userFullName = `${account.confJson.owner.fname} ${account.confJson.owner.lname}`;
            account.ownerEmail = account.confJson.owner.email;
            return account;
          });
          
        })
        .finally(() => {
          this.loading = false;
        });
  },
     
    aiAccountSelect(account) {
      const that = this;
      // eventBus.updateAIAccount(account);
      // that.updateActiveAIAccount(account);
    },
    aiProjectSelect(project) {
      const that = this;
         this.$router.replace({
          query: {
           account: project.accountId,
           project: project.id
          }
        })
      eventBus.updateAIAccount(project);
      that.updateActiveAIAccount(project);
    },
    updateAiAccountWithProject(account) {
      const that = this;
      setTimeout(() => {
        that.currentAIProject = account.name;
        that.aiAccountSelect(account);
      }, 10);
    },
    showDrawer() {
      eventBus.triggerProjectDrawer({status: false});
       if(this.accountsList.length == 1){   
        this.toggleEdit(this.accountsList[0]);       
          }
          else{
            setTimeout(() => {
        eventBus.triggerAccountDrawer({ status: true});
      }, 100);
          }      
    },
    showProfileDrawer() {
      setTimeout(() => {
        eventBus.triggerProfileDrawer({ status: true });
      }, 100);
    },
     showAccountDrawer() {
      setTimeout(() => {
        eventBus.triggerAccountDrawer({ status: true });
      }, 100);
    },
    settingPage() {
      if (this.$route.name !== "aiProjects") {
        this.$router.push("projects").catch(() => {});
      }
    },
    logout() {
      const that = this;
      that.userLogout().then(res => {
         that.$router.push({
          name: "login"
        })
      })
    },
    changeLanguage(val) {
      this.$i18n.locale = val;
    },
    updateAIProject(project) {
      const that = this;
      if (project !== undefined) {
        that.currentAIProjectOBJ = project;
        setTimeout(() => {
          that.currentAIProject = project.name;
          that.aiProjectSelect(project);
        }, 10);
      } else {
        that.updateAIProject(that.currentAIProjectOBJ);
      }
    },
    openEditProjectDrawer() {
      const that = this;
      this.$store.commit('SET_EDIT_SOURCE' , {accountId: this.currentAIProjectOBJ.accountId ,projectId: this.currentAIProjectOBJ.id })
      eventBus.triggerProjectDrawer({
        status: true,
        actionType: "edit",
        projectId: that.currentAIProjectOBJ.id
      });
    },
    updateDarkTheme() {
      try {
        if (this.darkTheme == true) {
          document.querySelector("body").classList.remove("dark");
          Storage.setItem("theme", "white");
          this.$q.dark.set(false);
        } else {
          const { body } = document;
          body.classList.add("dark");
          Storage.setItem("theme", "dark");
          this.$q.dark.set(true);
        }
        this.$store.commit("SET_DARK_THEME", !this.darkTheme);
      } catch {
        Storage.setItem("theme", !this.darkTheme);
        location.reload();
      }
    },
  },
  watch: {
    isLoggedIn(newVal) {
      const that = this;
      try {
        if (newVal) {
          that.getActiveAIAccounts({accountId: this.currentUser.accountId});
        }
      } catch (err) {
        console.log("isLoggedIn err", err);
      }
    },
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
        if (this.currentAIProject !== "") {
          that.currentAIAccount = _.find(newVal, account => account.id == that.currentAIProjectOBJ.accountId) || newVal[0];
          const currentProject = _.find( that.currentAIAccount.projects,  project => project.id == that.currentAIProjectOBJ.id) || newVal[0].projects[0];
          if(currentProject === undefined && this.$route.name !== 'aiProjects'){
            setTimeout(() => {
              that.$router.push({name: 'aiProjects'}).catch((err) => {})
            }, 100);
          }
          that.updateAIProject(currentProject);
        } else {
          that.currentAIAccount =  _.find(newVal, account => account.id == that.$route.query.account) || newVal[0];
          const currentProject =  _.find(that.currentAIAccount.projects, project => project.id == that.$route.query.project) || newVal[0].projects[0];
          if(currentProject === undefined && this.$route.name !== 'aiProjects'){
            setTimeout(() => {
              that.$router.push({name: 'aiProjects'}).catch((err) => {})
            }, 100);
          }
          that.updateAIProject(currentProject);
        }
      }
    },
    currentAIAccount(newVal, oldVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.aiAccountSelect(newVal);
        }
      }
    },
    currentAIProject(newVal) {
      if (typeof newVal === "object") {
        this.currentAIProject = newVal.name;
      }
    }
  },
  created() {
    this.mdiTranslate = mdiTranslate;
  },
  mounted() {
    this.loadAIAccounts();  
    if (this.isLoggedIn) {
      this.getActiveAIAccounts({accountId: this.currentUser.accountId});
    }
  },
  
};
