//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import eventBus from "@/plugins/eventBus";
// user components
import noBoard from "@/components/shared/noBoard";
import { mapGetters, mapActions } from "vuex";
import * as _ from "underscore";
import basic from "./user/basic";
import management from "./user/management";
// aiAccount components
import aiAccountManagment from "./aiAccount/management";

export default {
  name: "accountDrawer",
  components: {
    basic,
    management,
    aiAccountManagment,
    noBoard
  },
  data() {
    return {
      showDrawer: false,
      tab: "Management",
      currentAIAccount: "",
      updateUserLoading: false,
      outerWidth: 85,
      boxShadow: ""
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      selectedAIAccount: "getCurrentAIAccount",
      userDrawer: "getUserDrawer",
      aiAccountDrawer: "getAiAccountDrawer"
    }),
    drawerWidth() {
      if (window.innerWidth > 960) {
        return Math.floor((this.outerWidth / 100) * window.innerWidth);
      }
      return Math.floor((this.outerWidth / 100) * window.innerWidth);
    }
  },
  methods: {
    ...mapActions({
      userData: "userData"
    }),    
    toggleDrawer(data) {
      this.getUserData();
      this.showDrawer = data.status;
      this.tab = 'accountManagment';
    },
    closeDrawer() {
      if (this.userDrawer == true) {
        this.$store.commit("SET_USER_DRAWER", false);
      }
      eventBus.triggerAccountDrawer(false);
      this.showDrawer = false;
    },
    saveNewUser() {
      eventBus.createUser();
    },
    updateCurrentUser() {
      eventBus.updateUser();
    },
    updateMyProfile() {
      const that = this;
      that.updateUserLoading = true;
      eventBus.updateMyProfile();
      setTimeout(() => {
        that.updateUserLoading = false;
      }, 1000);
    },
    getUserData() {
      const that = this;
      const userToken = this.$store.getters.getUserToken;
      const userId = this.$store.getters.getUserId;
      that.userData({ token: userToken, id: userId });
    }
  },
  watch: {
    aiAccounts(newVal) {
      const that = this;
      if (newVal.length) {
        that.currentAIAccount = _.find(newVal, account => account.id == that.selectedAIAccount.id);
      }
    },
    currentAIAccount(newVal) {
      const that = this;
      if (newVal) {
        if (newVal.name) {
          that.currentAIAccount = newVal.name;
          that.$store.commit("SET_ADMIN_AI_ACCOUNT", newVal);
        }
      }
    },
    userDrawer(newVal) {
      if (newVal === true) {
        this.outerWidth = 100;
      } else {
        this.outerWidth = 85;
      }
    },
    aiAccountDrawer(newVal) {
      if (newVal === true) {
        this.outerWidth = 100;
      } else {
        this.outerWidth = 85;
      }
    },
    tab(newVal) {
      if (this.userDrawer == true) {
        this.$store.commit("SET_USER_DRAWER", false);
      }
    },
    showDrawer(newVal) {
      const that = this;
      if (newVal) {
        that.boxShadow = "-15vw 0px 0px 20px rgba(0,0,0,0.4)";
        that.currentAIAccount = _.find(
          that.aiAccounts,
          account => account.id == that.selectedAIAccount.accountId
        );
      } else {
        that.boxShadow = "";
      }
    }
  },
  created() {
    eventBus.$on("triggerAccountDrawer", this.toggleDrawer);
  },
  beforeDestroy() {
    eventBus.$off("triggerAccountDrawer", this.toggleDrawer);
  },
  destroyed() {
    eventBus.$off("triggerAccountDrawer", this.toggleDrawer);
  }  
};
